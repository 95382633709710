import { defineStore } from "pinia";
import services from "./services";
import { ElLoading, ElNotification, UploadUserFile } from "element-plus";
import { Proyecto } from "./ProyectosStore";
import { ProductoAcabado } from "./ProductosAcabadoStore";
import { Material } from "./MaterialesStore";
export enum Roles {
  Admin = "Admin",
  Manager = "Manager",
  User = "User",
}
export interface Sort {
  prop: string;
  order: string;
}
export interface ValidateData {
  [key: string]: boolean;
}
export interface ValidateDataItem {
  idPA: number;
  ValidateData: ValidateData;
}
export interface Configuracion {
  Key: string;
  Valor: string;
}
export interface Jerarquia {
  NODE_LIST: NODE_LIST;
}
export interface NODE_LIST {
  BAPIMAT_PRODH: BAPIMAT_PRODH[];
}
export interface BAPIMAT_PRODH {
  BROTHER_PROD_HIER: string;
  LANGU: string;
  LANGU_ISO: string;
  NODE_LEVEL: string;
  NODE_TEXT: string;
  PARENT_PROD_HIER: string;
  PROD_HIER: string;
  TEXT: string;
}
export interface Equipo {
  Nombre: string;
  SubEquipos: SubEquipo[];
  Key:string;
}
export interface SubEquipo {
  Nombre: string;
  Key:string;
}
export interface UploadUserFileExtended extends UploadUserFile {
  downloadUrl: string;
  siteId: string;
  itemId: string;
}
export interface AppRole {
  id: string
  displayName: string
  value: string
}
export const useCommonStore = defineStore("CommonStore", {
  // convert to a function
  state: () => ({
    Familias: [] as BAPIMAT_PRODH[] | undefined,
    Subfamilias: [] as BAPIMAT_PRODH[] | undefined,
    Marcas: [] as string[] | undefined,
    Formatos: [] as BAPIMAT_PRODH[] | undefined,
    TiposProductos: [] as string[] | undefined,
    CanalesVentas: [] as string[] | undefined,
    TiposVentas: [] as string[] | undefined,
    CodigosBarrasNuevos: [] as string[] | undefined,
    ForoAprobacionPA: [] as string[] | undefined,
    CategoriasMaterial: [] as string[] | undefined,
    Acabados: [] as string[] | undefined,
    Jerarquia: {} as Jerarquia | undefined,
    Equipos: [] as Equipo[],
    AppRoles: [] as AppRole[],
    Agencias: [] as string[] | undefined,
    TooltipPA:  [] as string[] | undefined,
    TooltipMaterial: [] as string[] | undefined,
  }),
  actions: {
    async GetDatosMaestros() {
      await services.SAPService.getHierarchy()
        .then((result: { data: Jerarquia }) => {
          this.Jerarquia = result.data;
        })
        .catch((error: Error) => {
          console.log(
            "Error al obtener la jerarquia de productos de SAP:",
            error
          );
        });
      await services.ConfiguracionService.getAll().then(
        (result: { data: Configuracion[] }) => {
          result.data.forEach((config) => {
            switch (config.Key) {
              case "Acabados":
                this.Acabados = JSON.parse(config.Valor);
                break;
              case "ForoAprobacionPA":
                this.ForoAprobacionPA = JSON.parse(config.Valor);
                break;
              case "CodigosBarrasNuevos":
                this.CodigosBarrasNuevos = JSON.parse(config.Valor);
                break;
              case "TiposVentas":
                this.TiposVentas = JSON.parse(config.Valor);
                break;
              case "CanalesVentas":
                this.CanalesVentas = JSON.parse(config.Valor);
                break;
              case "TiposProductos":
                this.TiposProductos = JSON.parse(config.Valor);
                break;
              case "Equipos":
                this.Equipos = JSON.parse(config.Valor);
                break;
              case "CategoriasMaterial":
                this.CategoriasMaterial = JSON.parse(config.Valor);
                break;
              case "Agencias":
                this.Agencias = JSON.parse(config.Valor);
                break;
                case "Marcas":
                this.Marcas = JSON.parse(config.Valor);
                break;
                case "TooltipPA":
                  this.TooltipPA = JSON.parse(config.Valor);
                  break;
                  case "TooltipMaterial":
                    this.TooltipMaterial = JSON.parse(config.Valor);
                    break;
              default:
                break;
            }
          });
        }
      );

      this.Familias =
        this.Jerarquia &&
        this.Jerarquia.NODE_LIST.BAPIMAT_PRODH.filter(
          (item) => item.NODE_LEVEL === "1"
        );
      this.Subfamilias =
        this.Jerarquia &&
        this.Jerarquia.NODE_LIST.BAPIMAT_PRODH.filter(
          (item) => item.NODE_LEVEL === "2"
        );
        
      this.Formatos =
        this.Jerarquia &&
        this.Jerarquia.NODE_LIST.BAPIMAT_PRODH.filter(
          (item) => item.NODE_LEVEL === "3"
        );
    },
    async CreatePlanner(proyecto:Proyecto) {
      try {
        services.PlannerService.createPlanner(proyecto);
      } catch (error) {
        console.error("Error al crear el planner:", error);
      }
    },
    async UpdatePlannerTasks(proyecto:Proyecto, productoAcabado:ProductoAcabado|null, material:Material|null) {
      try {
        await services.PlannerService.updatePlannerTasks(proyecto, productoAcabado, material);
      } catch (error) {
        console.error("Error al actualizar tareas del planner:", error);
      }
    },
    DeletePlannerTasks(id:number, tipoTarea:string) {
      try {
        services.PlannerService.deletePlannerTasks(id, tipoTarea);
      } catch (error) {
        console.error("Error al actualizar tareas del planner:", error);
      }
    },
    async SetAppRoles (appRoles:AppRole[]) {
      this.AppRoles = appRoles
  },
  GuardarDatosMaestros(key:string, values:string[]|Equipo[]|undefined) {
    const ElLoadingInstance = ElLoading.service({ fullscreen: true });
    const dataConfig: Configuracion = {
      Key: key,
      Valor: JSON.stringify(values),
    };
    services.ConfiguracionService.update(dataConfig)
      .then(() => {
        ElLoadingInstance.close();
        ElNotification.success({
          showClose: true,
          message: "Se ha guardado correctamente " + key,
        });
      })
      .catch(() => {
        ElLoadingInstance.close();
        ElNotification.error({
          showClose: true,
          message: "Se ha producido un error al guardar " + key,
        });
      });
  },
  SetDatosMaestrosStore(key:string, values:string[]) {
    switch (key) {
      case "Acabados":
        this.Acabados = values;
        break;
      case "ForoAprobacionPA":
        this.ForoAprobacionPA = values;
        break;
      case "CodigosBarrasNuevos":
        this.CodigosBarrasNuevos = values;
        break;
      case "TiposVentas":
        this.TiposVentas = values;
        break;
      case "CanalesVentas":
        this.CanalesVentas = values;
        break;
      case "TiposProductos":
        this.TiposProductos = values;
        break;
      case "CategoriasMaterial":
        this.CategoriasMaterial = values;
        break;
      case "Agencias":
        this.Agencias = values;
        break;
        case "Marcas":
        this.Marcas = values;
        break;
        case "TooltipPA":
        this.TooltipPA = values;
        break;
        case "TooltipMaterial":
          this.TooltipMaterial = values;
          break;
    }
  }
}
});
