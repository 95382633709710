import { defineStore } from "pinia";
import services from "./services";
import { Material, useMaterialesStore } from "./MaterialesStore";
import { UploadUserFile } from "element-plus";
import { Proyecto, useProyectosStore } from "./ProyectosStore";
import Helper from "@/common/Helper";
export interface ProductoAcabado {
  IdPA: number;
  IdProyecto: number;
  Codigo: string;
  Descripcion: string;
  Foto: string | undefined;
  TipoMaterial: string | undefined;
  ItemPromocional: boolean;
  FormulaNocilla: string;
  ForoAprobacionPA: string | undefined;
  Marca: string;
  Familia: string;
  Subfamilia: string;
  FormatoComercial: string;
  UnidadesUMBCaja: number;
  PesoNeto: number | undefined;
  UnidadMedidaBasicaUMB: string | undefined;
  CodigoEANNuevo: string | undefined;
  EANReferencia: string | undefined;
  EANReferenciaOriginal: string | undefined;
  TipoProducto?: string | undefined;
  TipoVenta: string | undefined;
  CanalVenta: string | undefined;
  DiasTotalesConservacion: number;
  Cuarentena: number;
  CosteEstandarUMB: number;
  PrecioPuntoVerdeUMB: number;
  VidaComercial: number;
  Batch: number;
  UnidadPrecioUMB: number;
  ReferenciaModelo: string;
  TempId?: string;
  Deleted?: boolean;
  Documentos?: string;
  FormatoExpositivo: string;
  Lineal: boolean;
  RefInterna: string;
  EditarCodigo: boolean;
}
export interface DocumentoData {
  downloadUrl: string;
  webUrl: string;
  name: string;
  siteId: string;
  itemId: string;
}
// export interface ReferenciaMateriales extends Referencia {
//     Materiales: Material[]
// }
export const useProductosAcabadoStore = defineStore("ProductosAcabadoStore", {
  // convert to a function
  state: () => ({
    ProductosAcabados: [] as ProductoAcabado[] | undefined,
    AllProductosAcabadosEnCurso: [] as ProductoAcabado[] | undefined,
  }),
  getters: {
    GetByIds(state) {
      return (idsReferencia: string[] | undefined) =>
        state.ProductosAcabados?.filter((m: ProductoAcabado) =>
          idsReferencia ? idsReferencia.indexOf(m.Codigo) > -1 : false
        );
    },
  },
  actions: {
    EmptyProductosAcabados() {
      this.ProductosAcabados = [];
    },
    async GetAllProductoAcabadoEnCurso () {
      await services.ProductoAcabadoService.getAllProductoAcabadoEnCurso()
      .then((result: { data: ProductoAcabado[] }) => {
        this.AllProductosAcabadosEnCurso = result.data;
      })
      .catch((error: Error) => {
        console.error("Error al obtener los productos acabados :", error);
      });
    },
    async GetProductoAcabadoById(idProyecto: number) {
      await services.ProductoAcabadoService.getByIdProyecto(idProyecto)
        .then((result: { data: ProductoAcabado[] }) => {
          this.ProductosAcabados = result.data;
        })
        .catch((error: Error) => {
          console.error("Error al obtener los productos acabados by idproyecto", error);
        });
    },
    async AddProductoAcabado(
      data: ProductoAcabado
    ): Promise<ProductoAcabado | undefined> {
      const ProyectosStore = useProyectosStore();
      const { findMissingNumber } = Helper();
      try {
        let refInterna = 1;
        if (this.ProductosAcabados && this.ProductosAcabados.length > 0) {
          const refInternas = this.ProductosAcabados.filter(
            (pa) =>
              pa.RefInterna !== undefined &&
              pa.RefInterna !== null &&
              pa.RefInterna !== ""
          ).map((item) => parseInt(item.RefInterna.replace("R", ""), 10));
          refInterna = findMissingNumber(refInternas);
        }
        data.RefInterna = "R" + refInterna;
        const MaterialesStore = useMaterialesStore();
        const result = await services.ProductoAcabadoService.add(data);
        if (!ProyectosStore.ProyectoSelected.IdGrupoPlanner) {
          await ProyectosStore.GetProyectoById(
            ProyectosStore.ProyectoSelected.IdProyecto
          );
        }
        if (ProyectosStore.ProyectoSelected.IdGrupoPlanner) {
          services.AzureService.createFolderSP(
            ProyectosStore.ProyectoSelected.IdGrupoPlanner,
            data.RefInterna
          );
        }
        console.log("ProductoAcabado añadido correctamente");

        if (this.ProductosAcabados) {
          const index = this.ProductosAcabados.findIndex(
            (i) => i.TempId === data.TempId
          );
          if (index && index > -1) this.ProductosAcabados[index] = result.data;
          else this.ProductosAcabados.push(result.data);
        }
        MaterialesStore.EmptyMaterialesPA()
        if(result.data.ReferenciaModelo) {
       await MaterialesStore.MaterialesByReferencia(
          Number(result.data.ReferenciaModelo)
        )
          if (MaterialesStore.Materiales) {
            for (let m = 0; m < MaterialesStore.Materiales.length; m++) {
              const material = MaterialesStore.Materiales[m];
              let refInterna = 1;
              if (MaterialesStore.MaterialesPA) {
                const refInternas = MaterialesStore.MaterialesPA.filter(
                  (pa) =>
                    pa.RefInterna !== undefined &&
                    pa.RefInterna !== null &&
                    pa.RefInterna !== ""
                ).map((item) => parseInt((item.RefInterna as string).replace("M", ""), 10));

                refInterna = findMissingNumber(refInternas);
              }
              material.RefInterna = "M" + refInterna;

              const dataMaterial: Material = {
                IdMaterial: 0,
                IdPA: result.data.IdPA,
                Codigo: material.Codigo,
                Descripcion: material.Descripcion,
                Tipo: material.Tipo,
                Cantidad: material.Cantidad,
                UM: material.UM,
                CategoriaMaterial: material.CategoriaMaterial ?? "",
                Agencia: material.Agencia ?? "",
                PruebaColor: material.PruebaColor ?? false,
                Diseno3D: material.Diseno3D ?? false,
                ModificarFichaEtiquetado:
                  material.ModificarFichaEtiquetado ?? false,
                ModificarPlano: material.ModificarPlano ?? false,
                Acabado: material.Acabado ?? "",
                LlevaLicencia: material.LlevaLicencia ?? false,
                Referencia: material.Referencia,
                CambioDiseno: material.CambioDiseno,
                RefInterna: material.RefInterna,
                CodigoReemplazado:material.Codigo,
                PrecioUMB: material.PrecioUMB
              };
              const materialAdded =
                await services.MaterialService.add(dataMaterial);
              MaterialesStore.UpdateMaterialesPAStore(materialAdded.data);
            }
          }
        }
        return result.data;
      } catch (error: unknown) {
        console.error("Error al añadir el producto acabado:", error);
        throw error;
      }
      return undefined;
    },
    async UpdateProductoAcabado(data: ProductoAcabado): Promise<void> {
      const { findMissingNumber } = Helper();
      const ProyectosStore = useProyectosStore();
      if (!data.RefInterna) {
        let refInterna = 1;
        if (this.ProductosAcabados && this.ProductosAcabados.length > 0) {
          const refInternas = this.ProductosAcabados.filter(
            (pa) =>
              pa.RefInterna !== undefined &&
              pa.RefInterna !== null &&
              pa.RefInterna !== ""
          ).map((item) => parseInt(item.RefInterna.replace("R", ""), 10));
          refInterna = findMissingNumber(refInternas);
        }
        data.RefInterna = "R" + refInterna;
      }
      await services.ProductoAcabadoService.update(data)
        .then(async () => {
          const index = this.ProductosAcabados?.findIndex(
            (i) => i.IdPA === data.IdPA
          );
          if (this.ProductosAcabados && index)
            this.ProductosAcabados[index] = data;
          console.log("ProductoAcabado update correctamente");

          if (!ProyectosStore.ProyectoSelected.IdGrupoPlanner) {
            await ProyectosStore.GetProyectoById(
              ProyectosStore.ProyectoSelected.IdProyecto
            );
          }
          if (ProyectosStore.ProyectoSelected.IdGrupoPlanner) {
            const result = await services.AzureService.getGraphFolderSP(
              ProyectosStore.ProyectoSelected.IdGrupoPlanner,
              data.RefInterna
            );
            if (
              !(result && result.value.length > 0 && result.value[0].webUrl)
            ) {
              services.AzureService.createFolderSP(
                ProyectosStore.ProyectoSelected.IdGrupoPlanner,
                data.RefInterna
              );
            }
          }
        })
        .catch((error: Error) => {
          console.error("Error al actualizar la fase:", error);
        });
    },
    async UploadFotoPA(data: ProductoAcabado, image: UploadUserFile) {
      if (image) {
        const imgUrl = await services.StorageService.uploadImage(
          image,
          data.IdProyecto + data.IdPA + "-" + image.name
        );
        data.Foto = imgUrl;
        this.UpdateProductoAcabado(data);
      }
    },
    async UploadDocumentSharepoint(
      data: ProductoAcabado,
      idGrupo: string | undefined,
      image: UploadUserFile
    ) {
      if (image && idGrupo) {
        const docUrl = await services.StorageService.uploadFileSharepoint(
          image,
          image.name,
          idGrupo,
          data.RefInterna
        );
        if (!data.Documentos) {
          data.Documentos = "";
        }
        data.Documentos += docUrl.fileUrl + ";";
        this.UpdateProductoAcabado(data);
      }
    },
    async DeleteFileSharepoint(siteId: string, itemId: string) {
      if (siteId && itemId) {
        await services.StorageService.deleteFileSharepoint(siteId, itemId);
      }
    },
    async DeleteProductoAcabado(idProductoAcabado: number) {
      await services.ProductoAcabadoService.delete(idProductoAcabado)
        .then(() => {
          console.log("ProductoAcabado eliminada correctamente");
        })
        .catch((error: Error) => {
          console.error("Error al eliminar la fase:", error);
        });
    },
    DeleteProductoAcabadoStore(data: ProductoAcabado) {
      let index = -1;
      if (this.ProductosAcabados) {
        if (data.IdPA > 0) {
          index = this.ProductosAcabados.findIndex(
            (pa) => pa.IdPA === data.IdPA
          );
        } else {
          index = this.ProductosAcabados.findIndex(
            (pa) => pa.TempId === data.TempId
          );
        }
        this.ProductosAcabados.splice(index, 1);
      }
    },
    async GetSharepointData(data: ProductoAcabado, proyecto: Proyecto) {
      const documents: { libraryUrl: string; documents: DocumentoData[] } = {
        libraryUrl: "",
        documents: [],
      };
      if (proyecto.IdGrupoPlanner) {
        let result = await services.AzureService.getGraphFolderSP(
          proyecto.IdGrupoPlanner,
          data.RefInterna
        );
        if (!result || (result && result.value.length === 0)) {
          await services.AzureService.createFolderSP(
            proyecto.IdGrupoPlanner,
            data.RefInterna
          );
          result = await services.AzureService.getGraphFolderSP(
            proyecto.IdGrupoPlanner,
            data.RefInterna
          );
        }
        if (result && result.value.length > 0) {
          documents.libraryUrl = result.value[0].webUrl;
          const resultData = await services.AzureService.getGraphItemsSP(
            proyecto.IdGrupoPlanner,
            result.value[0].id
          );
          if (resultData && resultData.value.length > 0) {
            resultData.value.forEach((doc: any) => {
              documents.documents.push({
                downloadUrl: doc["@microsoft.graph.downloadUrl"],
                webUrl: doc.webUrl,
                name: doc.name,
                siteId: doc.parentReference.siteId,
                itemId: doc.id,
              });
            });
          }
        }
      }
      return documents;
    },
    AddProductoAcabadoStore(data:ProductoAcabado) {
      this.ProductosAcabados?.push(data)
    }
  },
});
