import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import es from "element-plus/es/locale/lang/es";
import router from "./router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(ElementPlus, { locale: es });
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(router);
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.mount("#app");
app.directive("click-outside", {
  mounted(el: any, binding: any) {
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble;
    const handler = (e: any) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    el.clickOutsideEvent = handler;

    // add Event Listeners
    document.addEventListener("click", handler);
  },
  unmounted(el: any) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
    el.clickOutsideEvent = null;
  },
});
