import { Fase } from "@/store/FaseStore";
import { AxiosResponse, AxiosStatic } from "axios";

class FaseService {
  axios;
  baseUrl;
  baseUrlAux;
  constructor(axios: AxiosStatic, apiUrl: string) {
    this.axios = axios;
    this.baseUrl = `${apiUrl}Fase`;
    this.baseUrlAux = `${apiUrl}AllFase`;
  }

  getAll() {
    return this.axios.get(`${this.baseUrlAux}`);
  }

  getById (id: number): Promise<AxiosResponse<Fase>> {
    return this.axios.get(`${this.baseUrl}/?id=${id}`)
  }

  update (data: Fase): Promise<AxiosResponse<Fase>> {
    return this.axios.put(`${this.baseUrl}/?id=${data.Id}`, JSON.stringify(data))
  }

  add(data: Fase): Promise<AxiosResponse<Fase>> {
    return this.axios.post(`${this.baseUrl}`, JSON.stringify(data));
  }

  delete (id: number) {
    return this.axios.delete(`${this.baseUrl}/?id=${id}&deleteRefs=0`) // De momento las referecias no se eliminan
  }
}

export default FaseService;
