import { defineStore } from "pinia";
import services from "./services";
export interface Fase {
  Id: number;
  Descripcion: string;
  Duracion: number;
}
export interface FaseEstado {
  Fase: number;
  Estado: string;
}
export const useFasesStore = defineStore("FasesStore", {
  // convert to a function
  state: () => ({
    Fases: [] as Fase[] | undefined,
  }),
  actions: {
    async GetFases() {
      await services.FaseService.getAll()
        .then((result: { data: Fase[] }) => {
          this.Fases = result.data;
        })
        .catch((error: Error) => {
          console.error("Error al obtener las fases:", error);
        });
    },
    async GetFaseById(idFase: number) {
      await services.FaseService.getById(idFase)
        .then((result: { data: Fase }) => {
          return result.data;
        })
        .catch((error: Error) => {
          console.error("Error al obtener las fases:", error);
        });
    },
    async AddFase(data: Fase) {
      await services.FaseService.add(data)
        .then(() => {
          console.log("Fase añadida correctamente");
        })
        .catch((error: Error) => {
          console.error("Error al añadir la fase:", error);
        });
    },
    async UpdateFase(data: Fase) {
      await services.FaseService.update(data)
        .then(() => {
          console.log("Fase update correctamente");
        })
        .catch((error: Error) => {
          console.error("Error al actualizar la fase:", error);
        });
    },
    async DeleteFase(idFase: number) {
      await services.FaseService.delete(idFase)
        .then(() => {
          console.log("Fase eliminada correctamente");
        })
        .catch((error: Error) => {
          console.error("Error al eliminar la fase:", error);
        });
    },
  },
});
