import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common-layout" }

import { ref, defineAsyncComponent, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Roles, useCommonStore } from "@/store/CommonStore";
import { useUserStore } from "@/store/UserStore";

export default /*@__PURE__*/_defineComponent({
  __name: 'ConfiguracionView',
  setup(__props) {

// import { computed, onMounted, ref } from "vue";
// import { ElInput, ElTable, ElTableColumn } from "element-plus";
const router = useRouter();
const UserStore = useUserStore()
const CommonStore = useCommonStore()
const activeComponent = ref(""); // Estado para el componente activo
const userRoles = computed(() => {
  console.log( UserStore.userRoles)
  return UserStore.userRoles
});
// Función para cambiar el componente activo
const setActiveComponent = (componentName: string) => {
  activeComponent.value = componentName;
};

// Mapeo de componentes para la carga dinámica
const componentsMap = {
  ConfigurarTareas: defineAsyncComponent(
    () => import("../components/ConfigurarTareas.vue")
  ),
  ConfigurarFases: defineAsyncComponent(
    () => import("../components/ConfigurarFases.vue")
  ),
  ConfigurarEquipos: defineAsyncComponent(
    ()=> import("../components/ConfigurarEquipos.vue")
  ),
  ConfigurarListadoDatos: defineAsyncComponent(
    ()=> import("../components/ConfigurarListadoDatos.vue")
  )
};
const goBack = () => {
  router.go(-1);
};
onMounted(async () => {
  await CommonStore.GetDatosMaestros()
});

return (_ctx: any,_cache: any) => {
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_page_header, { onBack: goBack }, {
              content: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("span", { class: "text-large font-600 mr-3" }, " Configuración ", -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_aside, { width: "200px" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_menu, {
                  router: "",
                  class: "el-menu-vertical"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_menu_item, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (setActiveComponent('ConfigurarTareas')))
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createElementVNode("span", null, "Tareas", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_menu_item, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (setActiveComponent('ConfigurarFases')))
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createElementVNode("span", null, "Fases", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_menu_item, {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (setActiveComponent('ConfigurarEquipos')))
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createElementVNode("span", null, "Equipos", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_menu_item, {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (setActiveComponent('ConfigurarListadoDatos')))
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createElementVNode("span", null, "Listados de datos", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, null, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(componentsMap[activeComponent.value])))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, userRoles.value.includes(_unref(Roles).Admin)]
    ])
  ]))
}
}

})