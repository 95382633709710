import { defineStore } from "pinia";
import services from "./services";
import { UploadUserFile } from "element-plus";
import { FaseEstado, useFasesStore } from "./FaseStore";
import { useUserStore } from "./UserStore";
import { Roles } from "./CommonStore";
import { EstadosTareas } from "./TareasStore";
export interface Proyecto {
  IdProyecto: number;
  Descripcion: string;
  FechaServicio: string;
  Lideres?: string;
  Foto?: string;
  LoteMixto: boolean;
  LoteMixtoComponentes?: string;
  GruposAcceso: string;
  Fase?: string;
  EstimacionComercial?: number;
  Clientes?: string;
  Creado: string;
  CreadoPor: string;
  IdPlanner?: string;
  IdGrupoPlanner?: string;
  Equipo: string;
  Subnivel?: string;
  Completado: boolean;
  Duracion: number;
  Iniciado: boolean;
  KeyEquipo: string;
  TareasVencidas:number;
  TareasProximasVencer:number;

}

export const useProyectosStore = defineStore("ProyectosStore", {
  // convert to a function
  state: () => ({
    Proyectos: [] as Proyecto[] | undefined,
    ProyectoSelected: {} as Proyecto,
    // ProyectoReferenciasMateriales: {} as ProyectoReferenciasMateriales
  }),
  getters: {
    GetById(state) {
      return (id: number) =>
        state.Proyectos?.find((p: Proyecto) => p.IdProyecto === id);
    }
  },
  actions: {
    async GetProyectos() {
      const UserStore = useUserStore();
      this.Proyectos = [];
      await services.ProyectoService.getAll()
        .then((result: { data: Proyecto[] }) => {
          if (UserStore.userRoles.includes(Roles.Admin)) {
            this.Proyectos = result.data;
          } else {
            result.data.forEach((data) => {
              if (
                data.IdGrupoPlanner &&
                UserStore.userGroups.includes(data.IdGrupoPlanner)
              ) {
                this.Proyectos?.push(data);
              }
            });
          }
        })
        .catch((error: Error) => {
          console.error("Error al obtener los proyectos:", error);
        });
    },
    // GetProyectos () {
    //   this.Proyectos = datos.find(d => d.Proyectos)?.Proyectos
    // },
    async GetProyectoById(idProyecto: number) {
      try {
        const result = await services.ProyectoService.getById(idProyecto);
        this.ProyectoSelected = result.data;
      } catch (error) {
        console.error("Error al obtener el proyecto " + idProyecto, error);
      }
    },
    async AddProyecto(data: Proyecto, image: UploadUserFile) {
      const FaseStore = useFasesStore();
      try {
        if (data.Foto) {
          const imgUrl = await services.StorageService.uploadImage(
            image,
            data.Descripcion + "-" + image.name
          );
          data.Foto = imgUrl;
        }

        const faseEstados: FaseEstado[] = [];
        if (FaseStore.Fases) {
          FaseStore.Fases.forEach((fase) => {
            faseEstados.push({ Fase: fase.Id, Estado: EstadosTareas.ProyectoNoIniciado });
          });
          data.Fase = JSON.stringify(faseEstados);
        }
        data.Duracion = 0;
        const result = await services.ProyectoService.add(data);
        this.ProyectoSelected = result.data;
        console.log("Proyecto añadido correctamente", result);

        return result;
      } catch (error) {
        console.error("Error al añadir el proyecto:", error);
        return null;
      }
    },
    async UpdateProyecto(data: Proyecto, image: UploadUserFile) {
      if (
        data.Foto &&
        image.url &&
        (data.Foto?.indexOf("blob:") > -1 || data.Foto !== image.url)
      ) {
        const imgUrl = await services.StorageService.uploadImage(
          image,
          data.Descripcion + "-" + image.name
        );
        data.Foto = imgUrl;
      }
      const currentProyecto = await services.ProyectoService.getById(
        data.IdProyecto
      );
      if (!data.IdGrupoPlanner) {
        data.IdGrupoPlanner = currentProyecto.data.IdGrupoPlanner;
      }
      if (!data.IdPlanner) {
        data.IdPlanner = currentProyecto.data.IdPlanner;
      }
      if (data.Lideres && data.IdGrupoPlanner) {
        const formLideres = data.Lideres.split(";").sort();
        const originLideres = currentProyecto.data.Lideres
          ? currentProyecto.data.Lideres.split(";").sort()
          : [];

        const areEqual =
          JSON.stringify(formLideres) === JSON.stringify(originLideres);
        if (!areEqual) {
          const setformLideres = new Set(formLideres);
          const setoriginLideres = new Set(originLideres);

          const toAdd = formLideres.filter((id) => !setoriginLideres.has(id));
          if (toAdd.length > 0)
            services.PlannerService.addUsersGroup(toAdd, data.IdGrupoPlanner);

          const toRemove = originLideres.filter(
            (id) => !setformLideres.has(id)
          );
          if (toRemove.length > 0)
            services.PlannerService.removeUsersGroup(
              toRemove,
              data.IdGrupoPlanner
            );
        }
      }
      const result = await services.ProyectoService.update(data);
      this.ProyectoSelected = result.data;
      console.log("Proyecto actualizado correctamente", result);
    },
    async DeleteProyecto(idProyecto: number) {
      await services.ProyectoService.delete(idProyecto)
        .then(() => {
          console.log("Proyecto eliminada correctamente");
        })
        .catch((error: Error) => {
          console.error("Error al eliminar la fase:", error);
        });
    },
  },
});
