import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './assets/IdiliaPIM.png'


const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "login" }

import { computed, watch } from "vue";
import { useUserStore } from "./store/UserStore";
import { useCommonStore } from "./store/CommonStore";
import { useClientesStore } from "@/store/ClientesStore";
import { useFasesStore } from "./store/FaseStore";
import AutoLogin from './components/AutoLogin.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const ClientesStore = useClientesStore();
const UserStore = useUserStore();
const CommonStore = useCommonStore();
const FasesStore = useFasesStore();
const signedIn = computed(() => UserStore.signedIn);

watch(signedIn, async (signedIn) => {
  if (signedIn) {
    // Cargar datos
    FasesStore.GetFases();
    CommonStore.GetDatosMaestros();
    ClientesStore.GetClientes();
  }
});



return (_ctx: any,_cache: any) => {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_el_header, {
          id: "rowLogo",
          class: "main-header"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { gutter: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 4 }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("div", {
                      id: "divLogo",
                      class: "logo"
                    }, [
                      _createElementVNode("img", {
                        id: "imgLogo",
                        class: "logo",
                        src: _imports_0,
                        alt: "boltic logo"
                      })
                    ], -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 20 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(AutoLogin)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512), [
          [_vShow, _unref(UserStore).signedIn]
        ]),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_main, { class: "main-body" }, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})