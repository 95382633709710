/* eslint-disable */
import { Providers, Msal2Provider, ProviderState } from "@microsoft/mgt";
import moment from "moment";
import { AxiosStatic, Axios } from "axios";
import { User, useUserStore } from "@/store/UserStore";

// import { Buffer } from 'buffer'
// ProvidesState aparece como no definido
/* ProviderState {
    Loading -> 0,
    SignedOut -> 1,
    SignedIn -> 2
}
*/
import Buffer from "buffer";
import router from "@/router";
import { useCommonStore } from "@/store/CommonStore";

class Msal2ProviderCustom extends Msal2Provider
{
  getUserRoles() {
    return this.getAccount()?.idTokenClaims?.roles
  }
}

Providers.globalProvider = new Msal2ProviderCustom({
  clientId:
    process.env.VUE_APP_CLIENTID !== undefined
      ? process.env.VUE_APP_CLIENTID
      : "",
  authority: process.env.VUE_APP_AUTHORITY
});

class AzureService {
  axios;

  constructor(axios: AxiosStatic) {
    this.axios = axios;
  }

  configureProvider() {
    if (Providers.globalProvider) {
      this.loggedIn(Providers.globalProvider);
      Providers.globalProvider.onStateChanged(this.loggedIn);
    }
    Providers.onProviderUpdated(() => {
      if (Providers.globalProvider) {
        this.loggedIn(Providers.globalProvider);
        Providers.globalProvider.onStateChanged(this.loggedIn);
      }
    });
    return Providers.globalProvider.state;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loggedIn(provider: any) {
    const userStore = useUserStore();
    if (provider.state === 2) {
      this.getToken().then(async () => {
        // const appRoles = await this.getAppRoles()
        const roles = (<Msal2ProviderCustom>Providers.globalProvider).getUserRoles();
        if(roles)
          userStore.SetUserRoles(roles)
        this.getGraphMe().then(() => {
          userStore.SetSignin(true);          
          router.push({ name: "Dashboard" });          
        });
        
      });
    } else if (provider.state === 1) {
      userStore.SetSignin(false);
      localStorage.removeItem("app.token");
      router.push({ name: "Login"});
    }
  }

  async getGraphMe() {
    const userStore = useUserStore();
    await Providers.globalProvider.graph.client
      .api("me")
      .get()
      .then((response) => {
        userStore.SetCurrentUser(response);
      });
  }
  // async getAppRoles () {
  //   // https://graph.microsoft.com/v1.0/applications?$filter=appId eq 'cdc42104-665c-4187-a781-746956e4af9c'&$select=appRoles,id
  //   const result = await Providers.globalProvider.graph.client
  //   .api(`applications?$filter=appId eq '${process.env.VUE_APP_CLIENTID}'&$select=appRoles`)
  //   .get()
  //   if (result.value.length > 0) {
  //     return result.value[0].appRoles
  //   }
  //   return null

  // }
  // async getMyRoleAssignments () {
  //   const result =  await Providers.globalProvider.graph.client
  //   .api(`me/appRoleAssignments`)
  //   .get()
  //   return result.value
  // }
  async getGraphFolderSP(groupid: string, folderName: string) {
    return await Providers.globalProvider.graph.client
      .api(
        `groups/${groupid}/sites/root/drive/items?filter=name eq '${folderName}'`
      )
      .get();
    
  }
  async getGraphItemsSP(groupid: string, driveId: string) {
    return await Providers.globalProvider.graph.client
      .api(`groups/${groupid}/drive/items/${driveId}/children`)
      .get();
  }
  async updateGraphFolderSPName(
    groupid: string,
    oldFolderName: string,
    newFolderName: string
  ) {
    await Providers.globalProvider.graph.client
      .api(
        `groups/${groupid}/sites/root/drive/items?filter=name eq '${oldFolderName}'`
      )
      .patch({
        name: newFolderName,
      })
  }
  async createFolderSP(groupid: string, folderName: string) {
    const driveItem = {
      name: folderName,
      folder: {},
      "@microsoft.graph.conflictBehavior": "rename",
    };
    await Providers.globalProvider.graph.client
      .api(`groups/${groupid}/sites/root/drive/items`)
      .post(driveItem)
      .catch((error: any) => {
        console.error(error);
      });
  }

  async getGraphUserById(userId: string): Promise<User> {
    try {
      const user = await Providers.globalProvider.graph.client
        .api(`/users/${userId}`)
        .get();
      return user;
    } catch (error) {
      console.error("Error fetching user by ID:", error);
      throw error;
    }
  }
  async getToken() {
    return new Promise(function (resolve, reject) {
      let refreshToken = false;
      const data = localStorage.getItem("app.token");
      const authToken = data === null ? null : JSON.parse(data);
      if (authToken === null) refreshToken = true;
      if (authToken !== null && moment(authToken.expires).isBefore(moment())) {
        refreshToken = true;
      }
      
      if (refreshToken) {
        Providers.globalProvider.getAccessToken({
          scopes: [process.env.VUE_APP_API_SCOPE as string]
        })
          .then((data) => {
            const jsonToken = JSON.parse(
              Buffer.Buffer.from(data.split(".")[1], "base64").toString()
            );
            const dateExpires = new Date(jsonToken.exp * 1000);
            const objToken = { accessToken: data, expires: dateExpires };
            localStorage.setItem("app.token", JSON.stringify(objToken));
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        const jsonToken = JSON.parse(
          Buffer.Buffer.from(authToken.accessToken.split(".")[1], "base64").toString()
        );
        resolve(authToken.accessToken);
      }
    });
  }
  async getUserGroups () {
    try {
      const groups = await Providers.globalProvider.graph.client
        .api(`/me/transitiveMemberOf/microsoft.graph.group?$count=true`)
        .get();
      return groups;
    } catch (error) {
      console.error("Error fetching user groups:", error);
      throw error;
    }
  } 
}
export default AzureService

