<template>
  <div class="common-layout">
    <el-container v-show="userRoles.includes(Roles.Admin)">
      <el-header>
        <el-page-header @back="goBack">
          <template #content>
            <span class="text-large font-600 mr-3"> Configuración </span>
          </template>
        </el-page-header>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu router class="el-menu-vertical">
            <el-menu-item @click="setActiveComponent('ConfigurarTareas')">
              <span>Tareas</span>
            </el-menu-item>
            <el-menu-item @click="setActiveComponent('ConfigurarFases')">
              <span>Fases</span>
            </el-menu-item>
            <el-menu-item @click="setActiveComponent('ConfigurarEquipos')">
              <span>Equipos</span>
            </el-menu-item>
            <el-menu-item @click="setActiveComponent('ConfigurarListadoDatos')">
              <span>Listados de datos</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <component :is="componentsMap[activeComponent]" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
// import { computed, onMounted, ref } from "vue";
// import { ElInput, ElTable, ElTableColumn } from "element-plus";
import { ref, defineAsyncComponent, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Roles, useCommonStore } from "@/store/CommonStore";
import { useUserStore } from "@/store/UserStore";
const router = useRouter();
const UserStore = useUserStore()
const CommonStore = useCommonStore()
const activeComponent = ref(""); // Estado para el componente activo
const userRoles = computed(() => {
  console.log( UserStore.userRoles)
  return UserStore.userRoles
});
// Función para cambiar el componente activo
const setActiveComponent = (componentName: string) => {
  activeComponent.value = componentName;
};

// Mapeo de componentes para la carga dinámica
const componentsMap = {
  ConfigurarTareas: defineAsyncComponent(
    () => import("../components/ConfigurarTareas.vue")
  ),
  ConfigurarFases: defineAsyncComponent(
    () => import("../components/ConfigurarFases.vue")
  ),
  ConfigurarEquipos: defineAsyncComponent(
    ()=> import("../components/ConfigurarEquipos.vue")
  ),
  ConfigurarListadoDatos: defineAsyncComponent(
    ()=> import("../components/ConfigurarListadoDatos.vue")
  )
};
const goBack = () => {
  router.go(-1);
};
onMounted(async () => {
  await CommonStore.GetDatosMaestros()
});
</script>
