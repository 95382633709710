import { PuntesProject, Material } from "@/store/MaterialesStore";
import { AxiosResponse, AxiosStatic } from "axios";

class MaterialService {
  axios;
  baseUrl;
  baseUrlAux;
  apiPuntes;
  constructor(axios: AxiosStatic, apiUrl: string, apiPuntes:string) {
    this.axios = axios;
    this.baseUrl = `${apiUrl}Material`;
    this.baseUrlAux = `${apiUrl}MaterialByIdPA`;
    this.apiPuntes = `${apiPuntes}`
  }

  getAll() {
    return this.axios.get(`${this.baseUrl}`);
  }

  getById (id: number): Promise<AxiosResponse<Material>> {
    return this.axios.get(`${this.baseUrl}/?id=${id}`)
  }

  getByIdPA (id: number): Promise<AxiosResponse<Material[]>> {
    return this.axios.get(`${this.baseUrlAux}/?id=${id}`)
  }

  update (data: Material): Promise<AxiosResponse<Material>> {
    return this.axios.put(`${this.baseUrl}/?id=${data.IdMaterial}`, JSON.stringify(data))
  }

  add(data: Material): Promise<AxiosResponse<Material>> {
    return this.axios.post(`${this.baseUrl}`, JSON.stringify(data));
  }

  delete (id: number) {
    return this.axios.delete(`${this.baseUrl}/?id=${id}&deleteRefs=0`) // De momento las referecias no se eliminan
  }

  addProjectPuntes (data: PuntesProject): Promise<AxiosResponse<string>> {
    return this.axios.post(`${this.apiPuntes}/CreateProject`, JSON.stringify(data))
  }
  getProjectStatusPuntes (projectId:string): Promise<AxiosResponse<string>> {
    return this.axios.post(`${this.apiPuntes}/GetProjectStatus`, JSON.stringify({projectID: projectId}))
  }
  
}

export default MaterialService;
