<template>
  <div>
    <el-row>
      <el-col
        :span="24"
        style="margin-top: 30%; text-align: center;"
      >
        <img
          class="logo"
          src="../assets/IdiliaPIM.png"
          alt="Idilia PIM logo"
        >
      </el-col>
    </el-row>
  </div>
</template>