import { AxiosStatic } from "axios";
import { UploadUserFile } from "element-plus";

class StorageService {
  axios
  baseUrl

  constructor(axios: AxiosStatic, apiUrl: string) {
    this.axios = axios;
    this.baseUrl = `${apiUrl}`;
  }

  // Función para subir la imagen a Azure Blob Storage
  async uploadImage(formData:UploadUserFile, filename:string): Promise<string> {

    const data = new FormData();
    if (formData.raw) {
      data.append("file", formData.raw);
      data.append("filename", filename);
    }
    const result = await this.axios.post(`${this.baseUrl}upload`, data);
    return result.data;
  }

  async uploadFileSharepoint(
    formData: UploadUserFile,
    filename: string,
    idGrupoPlanner: string,
    folderName: string
  ): Promise<{ fileUrl: string; message: string }> {
    const data = new FormData();
    if (formData.raw) {
      data.append("file", formData.raw);
      data.append("filename", filename);
      data.append("IdGrupoPlanner", idGrupoPlanner);
      data.append("folderName", folderName);
    }
    const result = await this.axios.post(
      `${this.baseUrl}UploadToSharePoint`,
      data
    );
    return result.data;
  }

  async deleteFileSharepoint(siteId: string, itemId: string) {
    const data = JSON.stringify({ SiteId: siteId, ItemId: itemId });
    await this.axios.post(`${this.baseUrl}DeleteFromSharePoint`, data);
  }
}


export default StorageService
