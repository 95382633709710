<template>
  <div>
    <el-page-header :icon="null">
      <template #title> Dashboard </template>
      <template #extra>
        <div class="button-container">
          <el-switch
            v-model="filterProyectoCompletado"
            style="margin-right: 20px"
            active-text="Proyectos completados"
            inactive-text="Proyectos en curso"
            inline-prompt
            size="large"
          />
          <el-button
            v-show="userRoles.includes(Roles.Admin)"
            round
            @click="clickLeanOffice"
            class="buttonIdilia"
            :icon="Setting"
            style="margin-top: 5px"
            >Lean Office</el-button
          >
        </div>
      </template>
      <template #default>
        <el-row>
          <el-col :span="4">
            <el-statistic title="Completados" :value="CalcularCompletados" />
          </el-col>
          <el-col :span="1">
            <el-divider direction="vertical" class="divider-large" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="PA's en curso" :value="CalcularPAsEnCurso" />
          </el-col>
          <el-col :span="1">
            <el-divider direction="vertical" class="divider-large" />
          </el-col>
          <el-col :span="4">
            <el-statistic
              title="Tareas fuera de plazo"
              :value="CalcularTareasVencidas"
            />
          </el-col>
          <el-col :span="1">
            <el-divider direction="vertical" class="divider-large" />
          </el-col>
          <el-col :span="4">
            <el-statistic
              title="Vencen los próximos 7 días"
              :value="CalcularTareasProximas"
            />
          </el-col>
          <el-col :span="1">
            <el-divider direction="vertical" class="divider-large" />
          </el-col>
          <el-col :span="4">
            <el-statistic title="Media de días" :value="CalcularMediaDias" />
          </el-col>
        </el-row>
        <el-row>
          <el-col
            :span="3"
            v-for="equipo in CommonStore.Equipos"
            :key="equipo.Nombre"
          >
            <el-button
              :key="equipo.Nombre"
              round
              :class="
                equipoSeleccionado?.Nombre === equipo.Nombre
                  ? 'buttonIdilia-light'
                  : 'buttonIdilia'
              "
              @click="filterEquipo(equipo, '')"
              >{{ equipo.Nombre }}</el-button
            >
          </el-col>
          <el-col :span="24 - CommonStore.Equipos.length * 3 - 3"> </el-col>
          <el-col :span="3">
            <el-button
              v-show="
                userRoles.includes(Roles.Manager) ||
                userRoles.includes(Roles.Admin)
              "
              round
              @click="NuevoProyecto()"
              class="buttonIdilia"
              :icon="Plus"
              >Nuevo proyecto</el-button
            >
          </el-col>
        </el-row>

        <el-row
          v-if="
            equipoSeleccionado &&
            equipoSeleccionado.SubEquipos &&
            equipoSeleccionado.SubEquipos.length > 0
          "
        >
          <el-col
            :span="3"
            v-for="subequipo in equipoSeleccionado.SubEquipos"
            :key="subequipo"
          >
            <el-button
              :key="subequipo.Key"
              round
              :class="
                subequipoSeleccionado === subequipo.Nombre
                  ? 'buttonIdilia-light'
                  : 'buttonIdilia'
              "
              @click="filterEquipo(equipoSeleccionado, subequipo.Nombre)"
              >{{ subequipo.Nombre }}</el-button
            >
          </el-col>
          <el-col :span="24 - CommonStore.Equipos.length * 3"> </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-table
              ref="tableProyectos"
              :data="proyectos"
              :default-sort="defaultSort"
              style="width: 100%"
              empty-text="No hay datos"
              :height="windowHeight"
              stripe
              scrollbar-always-on
              row-key="Id"
              @row-dblclick="rowDblClick"
            >
              <el-table-column
                prop="Descripcion"
                label="Proyecto"
                column-key="Descripcion"
                sortable
                fixed="left"
                align="left"
                min-width="100px"
              />
              <el-table-column
                v-for="fase in FasesStore.Fases"
                :key="fase.Id"
                :label="fase.Descripcion + '  (' + fase.Duracion + 'días)'"
                min-width="100px"
                prop="Fase"
                align="center"
              >
                <template #header>
                  {{ fase.Descripcion }}
                  <br />
                  ({{ fase.Duracion }} días)
                </template>
                <template #default="scope">
                  <span
                    :class="
                      'dot ' +
                      GetClassByStatus(
                        (
                          scope.row.Fase &&
                          JSON.parse(scope.row.Fase).find(
                            (f) => f.Fase === fase.Id
                          )
                        )?.Estado || EstadosTareas.NotStarted
                      )
                    "
                    style="text-align: center; margin-right: 10px"
                    :data-tooltip="
                      (
                        scope.row.Fase &&
                        JSON.parse(scope.row.Fase).find(
                          (f) => f.Fase === fase.Id
                        )
                      )?.Estado || ''
                    "
                  >
                  </span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" align="right" min-width="100px">
                <template #header>
                  <el-button
                    :icon="Refresh"
                    circle
                    class="icon-idilia"
                    style="margin: 10px"
                    @click="RefreshData"
                  />
                </template>
                <template #default="scope">
                  <el-button size="small" @click="abrirProyecto(scope.row)">
                    Detalle
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </template>
    </el-page-header>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { ElTable } from "element-plus";
import { Plus, Setting } from "@element-plus/icons-vue";
import { Sort, Equipo, useCommonStore, Roles } from "@/store/CommonStore";
import { useProyectosStore, Proyecto } from "@/store/ProyectosStore";
import { useMaterialesStore } from "@/store/MaterialesStore";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useFasesStore } from "@/store/FaseStore";
import Helper from "@/common/Helper";
import { useTareasStore, EstadosTareas } from "@/store/TareasStore";
import { useUserStore } from "@/store/UserStore";
import { Refresh } from "@element-plus/icons-vue";
import { useProductosAcabadoStore } from "@/store/ProductosAcabadoStore";

const ProyectosStore = useProyectosStore();
const ProductosAcabadosStore = useProductosAcabadoStore();
const FasesStore = useFasesStore();
const MaterialesStore = useMaterialesStore();
const TareasStore = useTareasStore();
const CommonStore = useCommonStore();
const UserStore = useUserStore();
const router = useRouter();
const { GetClassByStatus } = Helper();
const windowHeight = window.innerHeight - 355;
const filterProyectoCompletado = ref(false);
const tableProyectos = ref<InstanceType<typeof ElTable>>();
const defaultSort = computed(() => {
  const result: Sort = { prop: "proyecto", order: "ascending" };
  return result;
});
const signedIn = computed(() => UserStore.signedIn);
const userRoles = computed(() => {
  console.log(UserStore.userRoles);
  return UserStore.userRoles;
});
const equipoSeleccionado = ref<Equipo>();
const subequipoSeleccionado = ref("");
const proyectosKPIs = computed(() => {
  if (ProyectosStore.Proyectos) {
    return ProyectosStore.Proyectos.filter(
      (proyecto) =>
        (!(equipoSeleccionado.value && equipoSeleccionado.value.Nombre) ||
          proyecto.Equipo === equipoSeleccionado.value.Nombre) &&
        (!(equipoSeleccionado.value && equipoSeleccionado.value.Nombre) ||
          !subequipoSeleccionado.value ||
          proyecto.Subnivel === subequipoSeleccionado.value)
    );
  }
  return null;
});

const CalcularCompletados = computed(() => {
  if (proyectosKPIs.value) {
    const numProyectos = proyectosKPIs.value.length;
    const numCompletados = proyectosKPIs.value.filter(
      (proyecto) => proyecto.Completado
    ).length;
    return numCompletados + "/" + numProyectos;
  }
  return "0/0";
});

const CalcularPAsEnCurso = computed(() => {
  let result = 0
  if (proyectos.value) {
    const idsProyectos = proyectos.value.map(proyecto => proyecto.IdProyecto);
    if (ProductosAcabadosStore.AllProductosAcabadosEnCurso) {
      const productosAcabadosEnCurso = ProductosAcabadosStore.AllProductosAcabadosEnCurso.filter(
      producto => idsProyectos.includes(producto.IdProyecto)
      )
      if(productosAcabadosEnCurso) result = productosAcabadosEnCurso.length
    }
  }
  return result;
});
const CalcularTareasVencidas = computed(() => {
  if (proyectos.value) {
    return proyectos.value.reduce(
      (sum, current) => sum + current.TareasVencidas,
      0
    );
  }
  return "0";
});
const CalcularTareasProximas = computed(() => {
  if (proyectos.value) {
    return proyectos.value.reduce(
      (sum, current) => sum + current.TareasProximasVencer,
      0
    );
  }
  return "0";
});
const CalcularMediaDias = computed(() => {
  if (proyectosKPIs.value && proyectosKPIs.value.length > 0) {
    const proyectosCompletados = proyectosKPIs.value.filter(
      (proyecto) => proyecto.Completado
    );
    if (proyectosCompletados.length > 0) {
      const totalDays = proyectosCompletados.reduce(
        (sum, proyecto) => sum + proyecto.Duracion,
        0
      );
      return (totalDays / proyectosCompletados.length).toFixed(2).toString();
    }
  }
  return "0";
});
const proyectos = computed(() => {
  if (ProyectosStore.Proyectos) {
    return ProyectosStore.Proyectos.filter(
      (proyecto) =>
        (!(equipoSeleccionado.value && equipoSeleccionado.value.Nombre) ||
          proyecto.Equipo === equipoSeleccionado.value.Nombre) &&
        (!(equipoSeleccionado.value && equipoSeleccionado.value.Nombre) ||
          !subequipoSeleccionado.value ||
          proyecto.Subnivel === subequipoSeleccionado.value) &&
        proyecto.Completado === filterProyectoCompletado.value
    );
  }
  return ProyectosStore.Proyectos;
});
const filterEquipo = (equipo: Equipo, subEquipo: string) => {
  if (!subEquipo && equipo.Nombre === equipoSeleccionado.value?.Nombre) {
    equipoSeleccionado.value = undefined;
  } else {
    equipoSeleccionado.value = equipo;
  }
  if (subEquipo === subequipoSeleccionado.value) {
    subequipoSeleccionado.value = "";
  } else {
    subequipoSeleccionado.value = subEquipo;
  }
};
const NuevoProyecto = () => {
  MaterialesStore.EmptyMaterialesPA();
  router.push({ name: "Wizard", params: { Pid: 0 } });
};
const abrirProyecto = async (row: Proyecto) => {
  TareasStore.EmptyTareas();
  MaterialesStore.EmptyMaterialesPA();
  await ProyectosStore.GetProyectoById(row.IdProyecto);
  router.push({ name: "OnePage", params: { Pid: row.IdProyecto } });
};
const rowDblClick = (row: Proyecto) => {
  abrirProyecto(row);
};
const clickLeanOffice = () => {
  router.push({ name: "Configuracion" });
};
const RefreshData = () => {
  ProyectosStore.GetProyectos();
  ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
};
onMounted(() => {
  ProyectosStore.GetProyectos();
  ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
});
watch(signedIn, async (signedIn) => {
  if (signedIn) {
    ProyectosStore.GetProyectos();
    ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
  }
});
</script>
