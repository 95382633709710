<template>
  <div>
    <mgt-login
      v-pre
      id="mgtLogin">
      <!-- <template > -->
      <div data-type="signed-out">
        Sign In
      </div>
      <!-- </template> -->
    </mgt-login>
  </div>
</template>

<script lang="ts" setup>
import { MgtLogin,Providers } from '@microsoft/mgt';
import { useUserStore } from "@/store/UserStore";
import { onMounted } from 'vue';
import { registerMgtLoginComponent } from "@microsoft/mgt-components";

registerMgtLoginComponent()
const UserStore = useUserStore()

onMounted(async () => {
  UserStore.ConfigureProvider().then(() => {
    if (Providers.globalProvider.login &&
      Providers.globalProvider.getAllAccounts &&
      Providers.globalProvider.getAllAccounts().length === 0)
      Providers.globalProvider.login();
  });
});
</script>