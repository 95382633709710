import { AxiosStatic } from "axios";

class SAPService {
  axios
  apiSAP

  constructor (axios: AxiosStatic, apiSAP: string) {
    this.axios = axios
    this.apiSAP = `${apiSAP}`
  }

  getHierarchy () {
    return this.axios.post(`${this.apiSAP}Get_Product_Hierarchy/paths/invoke`)
  }

  getProductDetails(referencia: number, centro: string) {
    const data = {
      material: referencia,
      centro: centro,
    };
    return this.axios.post(`${this.apiSAP}Get_Material_Detail/paths/invoke`, data)
  }

  getBillOfMaterials(referencia: number, centro: string) {
    const data = {
      material: referencia,
      centro: centro,
    };
    return this.axios.post(`${this.apiSAP}Get_Materiales_BOM/paths/invoke`, data)
  }
}
export default SAPService;
