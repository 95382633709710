import { createRouter, RouteRecordRaw, createWebHashHistory } from "vue-router";
// import { useUserStore } from '@/store/UserStore'

import Login from "./views/LoginView.vue";
import Dashboard from "./views/DashboardView.vue";
import Wizard from "./views/WizardView.vue";
import NotFound from "./views/NotFound.vue";
import OnePage from "./views/OnePageView.vue";
// import ConfigurarTareas from "./views/ConfigurarTareas.vue";
import Configuracion from "./views/ConfiguracionView.vue"
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Dashboard",
    alias: "/Dashboard",
    component: Dashboard
  },
  {
    path: "/Wizard/:Pid",
    name: "Wizard",
    component: Wizard
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound
  },
  {
    path: "/Login",
    name: "Login",
    component: Login
  },
  {
    path: "/OnePage/:Pid",
    name: "OnePage",
    component: OnePage
  },
  {
    path: "/Configuracion",
    name: "Configuracion",
    component: Configuracion
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
