import { defineStore } from "pinia";
import services from "./services";

export interface User {
  // @odata.context:"https://graph.microsoft.com/v1.0/$metadata#users/$entity"
  businessPhones: [];
  displayName: string;
  givenName: string;
  id: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
}
export interface Group {
displayName:string;
id:string;
}
export interface IUserStore {
  CurrentUser: User;
  signedIn: boolean;
  userRoles: string[];
  userGroups: string[];
}

// interface UserAppRole extends UserRole {
//   roleName: string;
// }
export const useUserStore = defineStore("UserStore", {
  // convert to a function
  state: (): IUserStore => {
    return {
      CurrentUser: {} as User,
      signedIn: false as boolean,
      userRoles: [] as string[],
      userGroups: [] as string[]
    };
  },
  actions: {
    async ConfigureProvider(): Promise<void> {
      services.AzureService.configureProvider();
    },
    async SetSignin(signedIn: boolean): Promise<void> {
      this.signedIn = signedIn;
    },
    async SetCurrentUser(currentUser: User): Promise<void> {
      this.CurrentUser = currentUser;
      this.GetCurrentUserGroups()
    },
    async GetCurrentUserGroups() {
      const data = await services.AzureService.getUserGroups()
      this.userGroups = data.value.map((d:Group) => d.id);
      
    },
    async SetUserRoles (userRoles : string[]) : Promise<void> {
      this.userRoles = userRoles
    },
  },
});
