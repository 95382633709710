import { defineStore } from "pinia";
import services from "./services";
import Helper from "@/common/Helper";
import { useCommonStore } from "./CommonStore";
import { ProductoAcabado } from "./ProductosAcabadoStore";
import { Proyecto } from "./ProyectosStore";
import { ElNotification } from "element-plus";

export interface Material {
  IdMaterial: number;
  IdPA: number;
  Codigo: string;
  Descripcion: string;
  Tipo: string;
  Cantidad: number;
  UM: string;
  CategoriaMaterial?: string;
  Agencia?: string;
  PruebaColor?: boolean;
  Diseno3D?: boolean;
  ModificarFichaEtiquetado?: boolean;
  ModificarPlano?: boolean;
  Acabado?: string;
  LlevaLicencia?: boolean;
  Referencia?: string;
  CambioDiseno: boolean;
  RefInterna?: string;
  TempId?: string;
  Deleted?: boolean;
  CodigoReemplazado:string;
  IdProyectoPuntes?:string;
  PrecioUMB?:number;
}

export interface PuntesProject {
  projectname: string;
  projectdescription: string;
  projecttemplatename: string;
  descripcion: string;
  unidad: string;
  marca: string;
  familia: string;
  'codigo material':string;
  'codigo PA': string;
  'codigo PA Referencia': string;
  'cod. mat. reemplazado': string;
  'codigo ean/dun': string|undefined;
  'categoria material': string|undefined;
  agencia: string|undefined;
  prb: string;
  acabado: string|undefined;
}

export const useMaterialesStore = defineStore("MaletrialesStore", {
  // convert to a function
  state: () => ({
    Materiales: [] as Material[] | undefined,
    MaterialesPA: [] as Material[] | undefined,
  }),
  // getters: {
  //     MaterialByReferencia: state => (referencia: string | undefined) => {
  //         return state.Materiales?.filter((m:Material) => m.ReferenciaPA === referencia)
  //     }
  // },
  getters: {
    MaterialesByIds: (state) => {
      return (idsMateriales: string[] | undefined): Material[] => {
        return (
          state.Materiales?.filter((m: Material) =>
            idsMateriales ? idsMateriales.indexOf(m.Codigo) > -1 : false
          ) || []
        );
      };
    }, //,
    //   MaterialesByReferencia: (state) => {
    //     return (referencia: string): Material[] => {
    //         return state.Materiales?.filter((m: Material) => m.Referencia === referencia ) || []
    //       }
    //   }
  },
  actions: {
    async MaterialesByReferencia(referencia: number) {
      // Datos maestros
      await services.SAPService.getBillOfMaterials(referencia, "1110")
        .then((response) => {
          this.Materiales = [] as Material[];
          response.data.STB.STPOX.forEach(
            (item: {
              IDNRK: any;
              OJTXP: any;
              MTART: any;
              MENGE: any;
              MMEIN: any;
            }) => {
              const material: Material = {
                IdMaterial: -1,
                IdPA: -1,
                Codigo: item.IDNRK.replace(/^0+/, ""),
                Descripcion: item.OJTXP,
                Tipo: item.MTART,
                Cantidad: Number(item.MENGE),
                UM: item.MMEIN,
                CambioDiseno: false,
                CodigoReemplazado:item.IDNRK.replace(/^0+/, "")
              };
              this.Materiales?.push(material);
            }
          );
        })
        .catch((error: Error) => {
          console.error("Error al obtener la lista de materiales de SAP:", error);
        });
    },
    async GetMaterialesByProductoAcabado(idPA: number) {
      //llamada a bbdd
      // this.MaterialesPA = datos.find(d => d.MaterialesPA)?.MaterialesPA?.filter(m => m.IdPA === idPA)
      this.EmptyMaterialesPA()
      await services.MaterialService.getByIdPA(idPA)
        .then((result: { data: Material[] }) => {
          this.MaterialesPA = result.data;
        })
        .catch((error: Error) => {
          console.error("Error al obtener los materiales:", error);
        });
    },
    async AddMaterial(data: Material) {
      const {findMissingNumber} = Helper()
      if (!data.RefInterna) {
        let refInterna = 1;
        if (this.MaterialesPA) {
          const refInternas = this.MaterialesPA.filter(
            (pa) =>
              pa.RefInterna !== undefined &&
              pa.RefInterna !== null &&
              pa.RefInterna !== ""
          ).map((item) => parseInt((item.RefInterna as string).replace("M", ""), 10));

          refInterna = findMissingNumber(refInternas);
        }
        data.RefInterna = "M" + refInterna;
      }
      await services.MaterialService.add(data)
        .then(() => {
          if (!this.MaterialesPA) {
            this.MaterialesPA = [];
          }
          // this.MaterialesPA.push(data)
          this.GetMaterialesByProductoAcabado(data.IdPA);
          console.log("Material añadido correctamente");
        })
        .catch((error: Error) => {
          console.error("Error al añadir el material:", error);
        });
    },
    async UpdateMaterial(data: Material, productoAcabado:ProductoAcabado, proyecto:Proyecto, onlyUpdateData:boolean=false) {
      const CommonStore = useCommonStore()
      await services.MaterialService.update(data)
        .then(() => {
          if (proyecto.Iniciado && data.CambioDiseno && !onlyUpdateData) {
            CommonStore.UpdatePlannerTasks(proyecto, productoAcabado, data)
          }
          if (this.MaterialesPA) {
            const index = this.MaterialesPA?.findIndex(
              (m) => m.IdMaterial === data.IdMaterial
            );
            if (index > -1) {
              this.MaterialesPA[index] = data;
              console.log("Material update correctamente");
            }
          }
        })
        .catch((error: Error) => {
          console.error("Error al actualizar el material:", error);
        });
    },
    async DeleteMaterial(idMaterial: number, tipo:string) {
      const CommonStore = useCommonStore()
      const index = this.MaterialesPA?.findIndex(
        (m) => m.IdMaterial === idMaterial
      );
      if (index !== undefined && index > -1) {
        await services.MaterialService.delete(idMaterial)
          .then(() => {
            CommonStore.DeletePlannerTasks(idMaterial, tipo) // desde la function se borran las tareas de bbdd
            this.MaterialesPA?.splice(index, 1);
            console.log("Material eliminado correctamente");
          })
          .catch((error: Error) => {
            console.error("Error al eliminar el material:", error);
          });
      }
    },
    EmptyMaterialesPA() {
      this.MaterialesPA = [];
    },
    UpdateMaterialesPAStore(data: Material) {
      const index = this.MaterialesPA?.findIndex(
        (m) => m.IdMaterial === data.IdMaterial || (m.TempId !== undefined && m.TempId === data.TempId)
      );
      if (this.MaterialesPA && index !== undefined && index > -1) {
        this.MaterialesPA[index] = data;
      } else {
        this.MaterialesPA?.push(data);
      }
    },
    async EnviarDatosAPuntes (data:Material, prodAcabado: ProductoAcabado, proyecto: Proyecto) {
      const CommonStore = useCommonStore()
      const dataPuntes:PuntesProject = {
          projectname: prodAcabado.Codigo + ' - ' + data.Descripcion,
          projectdescription: 'created by PIMPRO',
          projecttemplatename: 'Boltic_Idilia_estandar', // TODO Cambiar por bbdd
          descripcion: proyecto.Descripcion,
          unidad: data.UM,
          marca: prodAcabado.Marca.toUpperCase(),
          familia: CommonStore.Familias?.filter((item) => item.PROD_HIER === prodAcabado.Familia)[0].TEXT as string,
          'codigo material':data.Codigo,
          'codigo PA':prodAcabado.Codigo,
          'codigo PA Referencia':prodAcabado.ReferenciaModelo,
          'cod. mat. reemplazado': data.CodigoReemplazado,
          'codigo ean/dun': prodAcabado.EANReferencia,
          'categoria material': data.CategoriaMaterial,
          agencia: data.Agencia,
          prb: data.PruebaColor? 'SI': 'NO',
          acabado: data.Acabado
        }
      const resultPuntes = await services.MaterialService.addProjectPuntes(dataPuntes)
      if(resultPuntes.data.indexOf('ERROR') === -1) {
        data.IdProyectoPuntes = resultPuntes.data
        this.UpdateMaterial(data, prodAcabado, proyecto, true)
      } else {
        ElNotification.error({
          showClose: true,
          message: "Se ha producido un error al enviar los datos a puntes.",
        });
      }
    },
    async GetEstadoProyectoPuntes(idProyectoPuntes:string) {
      const result = await services.MaterialService.getProjectStatusPuntes(idProyectoPuntes)
      return result.data
    }
  },
});
