import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import services from "./services";
interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders;
}

// Interceptors
axios.interceptors.request.use(
  async (config): Promise<AdaptAxiosRequestConfig> => {
    return new Promise(function (resolve, reject) {
      try {
        if (config.headers) {
          services.AzureService.getToken().then((accessToken) => {
            config.headers.Authorization = `Bearer ${accessToken}`;
            config.headers.Accept = "application/json, text/plain, */*";

            if (config.url && config.url.indexOf("/storage/") > -1) {
              config.headers["Content-Type"] = "multipart/form-data";
            } else {
              config.headers["Content-Type"] = "application/json";
            }

            resolve(config);
          });
        }
      } catch (error: unknown) {
        reject(error);
      }
    });
  },
  function (err) {
    return Promise.reject(err);
  }
);
