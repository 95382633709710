import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { MgtLogin,Providers } from '@microsoft/mgt';
import { useUserStore } from "@/store/UserStore";
import { onMounted } from 'vue';
import { registerMgtLoginComponent } from "@microsoft/mgt-components";


export default /*@__PURE__*/_defineComponent({
  __name: 'AutoLogin',
  setup(__props) {

registerMgtLoginComponent()
const UserStore = useUserStore()

onMounted(async () => {
  UserStore.ConfigureProvider().then(() => {
    if (Providers.globalProvider.login &&
      Providers.globalProvider.getAllAccounts &&
      Providers.globalProvider.getAllAccounts().length === 0)
      Providers.globalProvider.login();
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, _cache[0] || (_cache[0] = [
    _createElementVNode("mgt-login", { id: "mgtLogin" }, [
      _createElementVNode("div", { "data-type": "signed-out" }, " Sign In ")
    ], -1)
  ])))
}
}

})