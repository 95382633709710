import { Material } from "@/store/MaterialesStore";
import { ProductoAcabado } from "@/store/ProductosAcabadoStore";
import { Proyecto } from "@/store/ProyectosStore";
import { AxiosResponse, AxiosStatic } from "axios";

class PlannerService {
  axios;
  baseUrl;

  constructor(axios: AxiosStatic, apiUrl: string) {
    this.axios = axios;
    this.baseUrl = `${apiUrl}`;
  }

  // Función para subir la imagen a Azure Blob Storage
  async createPlanner(proyecto: Proyecto): Promise<void> {
    const data = JSON.stringify({ Proyecto: proyecto });
    const result = await this.axios.post(`${this.baseUrl}CreatePlanner`, data);
    return result.data;
  }
  async getTareasByBucket(bucketId: string[]): Promise<AxiosResponse<any>> {
    const data = await this.axios.post(
      `${this.baseUrl}GetTareasByBucketId`,
      JSON.stringify(bucketId)
    );
    return data;
  }
  removeUsersGroup(toRemove: string[], IdGrupoPlanner: string) {
    const data = JSON.stringify({
      IdGrupoPlanner: IdGrupoPlanner,
      Users: toRemove.join(";"),
    });
    this.axios.post(`${this.baseUrl}RemoveUsersGroup`,data);
  }
  addUsersGroup(toAdd: string[], IdGrupoPlanner: string) {
    const data = JSON.stringify({
      IdGrupoPlanner: IdGrupoPlanner,
      Users: toAdd.join(";"),
    });
    this.axios.post(`${this.baseUrl}AddUsersGroup`, data);
  }
  async updatePlannerTasks(proyecto:Proyecto, prodAcabado:ProductoAcabado|null, material: Material|null) {
    const data = JSON.stringify({
      Proyecto: proyecto,
      ProductoAcabado: prodAcabado,
      Material: material
    });
    await this.axios.post(`${this.baseUrl}UpdatePlannerTasks`, data);
  }
  deletePlannerTasks(id:number, tipoTarea: string) {
    this.axios.post(`${this.baseUrl}DeletePlannerTasks?id=${id}&TipoTarea=${tipoTarea}`);
  }
}


export default PlannerService;
