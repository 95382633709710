import { defineStore } from "pinia";
import services from "./services";

export interface Cliente {
  Cod_cuenta: string;
  Cuenta: string;
}
export const useClientesStore = defineStore("ClientesStore", {
  // convert to a function
  state: () => ({
    Clientes: [] as Cliente[] | undefined,
  }),
  actions: {
    async GetClientes() {
      await services.ClienteService.getAll()
        .then((result: { data: Cliente[] }) => {
          this.Clientes = result.data;
        })
        .catch((error: Error) => {
          console.error("Error al obtener los clientes:", error);
        });
    },
  },
});
