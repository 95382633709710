import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "button-container" }
const _hoisted_2 = ["data-tooltip"]

import { ref, computed, watch } from "vue";
import { ElTable } from "element-plus";
import { Plus, Setting } from "@element-plus/icons-vue";
import { Sort, Equipo, useCommonStore, Roles } from "@/store/CommonStore";
import { useProyectosStore, Proyecto } from "@/store/ProyectosStore";
import { useMaterialesStore } from "@/store/MaterialesStore";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useFasesStore } from "@/store/FaseStore";
import Helper from "@/common/Helper";
import { useTareasStore, EstadosTareas } from "@/store/TareasStore";
import { useUserStore } from "@/store/UserStore";
import { Refresh } from "@element-plus/icons-vue";
import { useProductosAcabadoStore } from "@/store/ProductosAcabadoStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardView',
  setup(__props) {

const ProyectosStore = useProyectosStore();
const ProductosAcabadosStore = useProductosAcabadoStore();
const FasesStore = useFasesStore();
const MaterialesStore = useMaterialesStore();
const TareasStore = useTareasStore();
const CommonStore = useCommonStore();
const UserStore = useUserStore();
const router = useRouter();
const { GetClassByStatus } = Helper();
const windowHeight = window.innerHeight - 355;
const filterProyectoCompletado = ref(false);
const tableProyectos = ref<InstanceType<typeof ElTable>>();
const defaultSort = computed(() => {
  const result: Sort = { prop: "proyecto", order: "ascending" };
  return result;
});
const signedIn = computed(() => UserStore.signedIn);
const userRoles = computed(() => {
  console.log(UserStore.userRoles);
  return UserStore.userRoles;
});
const equipoSeleccionado = ref<Equipo>();
const subequipoSeleccionado = ref("");
const proyectosKPIs = computed(() => {
  if (ProyectosStore.Proyectos) {
    return ProyectosStore.Proyectos.filter(
      (proyecto) =>
        (!(equipoSeleccionado.value && equipoSeleccionado.value.Nombre) ||
          proyecto.Equipo === equipoSeleccionado.value.Nombre) &&
        (!(equipoSeleccionado.value && equipoSeleccionado.value.Nombre) ||
          !subequipoSeleccionado.value ||
          proyecto.Subnivel === subequipoSeleccionado.value)
    );
  }
  return null;
});

const CalcularCompletados = computed(() => {
  if (proyectosKPIs.value) {
    const numProyectos = proyectosKPIs.value.length;
    const numCompletados = proyectosKPIs.value.filter(
      (proyecto) => proyecto.Completado
    ).length;
    return numCompletados + "/" + numProyectos;
  }
  return "0/0";
});

const CalcularPAsEnCurso = computed(() => {
  let result = 0
  if (proyectos.value) {
    const idsProyectos = proyectos.value.map(proyecto => proyecto.IdProyecto);
    if (ProductosAcabadosStore.AllProductosAcabadosEnCurso) {
      const productosAcabadosEnCurso = ProductosAcabadosStore.AllProductosAcabadosEnCurso.filter(
      producto => idsProyectos.includes(producto.IdProyecto)
      )
      if(productosAcabadosEnCurso) result = productosAcabadosEnCurso.length
    }
  }
  return result;
});
const CalcularTareasVencidas = computed(() => {
  if (proyectos.value) {
    return proyectos.value.reduce(
      (sum, current) => sum + current.TareasVencidas,
      0
    );
  }
  return "0";
});
const CalcularTareasProximas = computed(() => {
  if (proyectos.value) {
    return proyectos.value.reduce(
      (sum, current) => sum + current.TareasProximasVencer,
      0
    );
  }
  return "0";
});
const CalcularMediaDias = computed(() => {
  if (proyectosKPIs.value && proyectosKPIs.value.length > 0) {
    const proyectosCompletados = proyectosKPIs.value.filter(
      (proyecto) => proyecto.Completado
    );
    if (proyectosCompletados.length > 0) {
      const totalDays = proyectosCompletados.reduce(
        (sum, proyecto) => sum + proyecto.Duracion,
        0
      );
      return (totalDays / proyectosCompletados.length).toFixed(2).toString();
    }
  }
  return "0";
});
const proyectos = computed(() => {
  if (ProyectosStore.Proyectos) {
    return ProyectosStore.Proyectos.filter(
      (proyecto) =>
        (!(equipoSeleccionado.value && equipoSeleccionado.value.Nombre) ||
          proyecto.Equipo === equipoSeleccionado.value.Nombre) &&
        (!(equipoSeleccionado.value && equipoSeleccionado.value.Nombre) ||
          !subequipoSeleccionado.value ||
          proyecto.Subnivel === subequipoSeleccionado.value) &&
        proyecto.Completado === filterProyectoCompletado.value
    );
  }
  return ProyectosStore.Proyectos;
});
const filterEquipo = (equipo: Equipo, subEquipo: string) => {
  if (!subEquipo && equipo.Nombre === equipoSeleccionado.value?.Nombre) {
    equipoSeleccionado.value = undefined;
  } else {
    equipoSeleccionado.value = equipo;
  }
  if (subEquipo === subequipoSeleccionado.value) {
    subequipoSeleccionado.value = "";
  } else {
    subequipoSeleccionado.value = subEquipo;
  }
};
const NuevoProyecto = () => {
  MaterialesStore.EmptyMaterialesPA();
  router.push({ name: "Wizard", params: { Pid: 0 } });
};
const abrirProyecto = async (row: Proyecto) => {
  TareasStore.EmptyTareas();
  MaterialesStore.EmptyMaterialesPA();
  await ProyectosStore.GetProyectoById(row.IdProyecto);
  router.push({ name: "OnePage", params: { Pid: row.IdProyecto } });
};
const rowDblClick = (row: Proyecto) => {
  abrirProyecto(row);
};
const clickLeanOffice = () => {
  router.push({ name: "Configuracion" });
};
const RefreshData = () => {
  ProyectosStore.GetProyectos();
  ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
};
onMounted(() => {
  ProyectosStore.GetProyectos();
  ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
});
watch(signedIn, async (signedIn) => {
  if (signedIn) {
    ProyectosStore.GetProyectos();
    ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_statistic = _resolveComponent("el-statistic")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_page_header = _resolveComponent("el-page-header")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_page_header, { icon: null }, {
      title: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode(" Dashboard ")
      ])),
      extra: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_switch, {
            modelValue: filterProyectoCompletado.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterProyectoCompletado).value = $event)),
            style: {"margin-right":"20px"},
            "active-text": "Proyectos completados",
            "inactive-text": "Proyectos en curso",
            "inline-prompt": "",
            size: "large"
          }, null, 8, ["modelValue"]),
          _withDirectives(_createVNode(_component_el_button, {
            round: "",
            onClick: clickLeanOffice,
            class: "buttonIdilia",
            icon: _unref(Setting),
            style: {"margin-top":"5px"}
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Lean Office")
            ])),
            _: 1
          }, 8, ["icon"]), [
            [_vShow, userRoles.value.includes(_unref(Roles).Admin)]
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 4 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_statistic, {
                  title: "Completados",
                  value: CalcularCompletados.value
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_divider, {
                  direction: "vertical",
                  class: "divider-large"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 4 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_statistic, {
                  title: "PA's en curso",
                  value: CalcularPAsEnCurso.value
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_divider, {
                  direction: "vertical",
                  class: "divider-large"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 4 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_statistic, {
                  title: "Tareas fuera de plazo",
                  value: CalcularTareasVencidas.value
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_divider, {
                  direction: "vertical",
                  class: "divider-large"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 4 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_statistic, {
                  title: "Vencen los próximos 7 días",
                  value: CalcularTareasProximas.value
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_divider, {
                  direction: "vertical",
                  class: "divider-large"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 4 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_statistic, {
                  title: "Media de días",
                  value: CalcularMediaDias.value
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CommonStore).Equipos, (equipo) => {
              return (_openBlock(), _createBlock(_component_el_col, {
                span: 3,
                key: equipo.Nombre
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_el_button, {
                    key: equipo.Nombre,
                    round: "",
                    class: _normalizeClass(
                equipoSeleccionado.value?.Nombre === equipo.Nombre
                  ? 'buttonIdilia-light'
                  : 'buttonIdilia'
              ),
                    onClick: ($event: any) => (filterEquipo(equipo, ''))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(equipo.Nombre), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "onClick"]))
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createVNode(_component_el_col, {
              span: 24 - _unref(CommonStore).Equipos.length * 3 - 3
            }, null, 8, ["span"]),
            _createVNode(_component_el_col, { span: 3 }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_el_button, {
                  round: "",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (NuevoProyecto())),
                  class: "buttonIdilia",
                  icon: _unref(Plus)
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Nuevo proyecto")
                  ])),
                  _: 1
                }, 8, ["icon"]), [
                  [_vShow, 
                userRoles.value.includes(_unref(Roles).Manager) ||
                userRoles.value.includes(_unref(Roles).Admin)
              ]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (
            equipoSeleccionado.value &&
            equipoSeleccionado.value.SubEquipos &&
            equipoSeleccionado.value.SubEquipos.length > 0
          )
          ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(equipoSeleccionado.value.SubEquipos, (subequipo) => {
                  return (_openBlock(), _createBlock(_component_el_col, {
                    span: 3,
                    key: subequipo
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_el_button, {
                        key: subequipo.Key,
                        round: "",
                        class: _normalizeClass(
                subequipoSeleccionado.value === subequipo.Nombre
                  ? 'buttonIdilia-light'
                  : 'buttonIdilia'
              ),
                        onClick: ($event: any) => (filterEquipo(equipoSeleccionado.value, subequipo.Nombre))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(subequipo.Nombre), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "onClick"]))
                    ]),
                    _: 2
                  }, 1024))
                }), 128)),
                _createVNode(_component_el_col, {
                  span: 24 - _unref(CommonStore).Equipos.length * 3
                }, null, 8, ["span"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, null, {
              default: _withCtx(() => [
                _createVNode(_unref(ElTable), {
                  ref_key: "tableProyectos",
                  ref: tableProyectos,
                  data: proyectos.value,
                  "default-sort": defaultSort.value,
                  style: {"width":"100%"},
                  "empty-text": "No hay datos",
                  height: windowHeight,
                  stripe: "",
                  "scrollbar-always-on": "",
                  "row-key": "Id",
                  onRowDblclick: rowDblClick
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      prop: "Descripcion",
                      label: "Proyecto",
                      "column-key": "Descripcion",
                      sortable: "",
                      fixed: "left",
                      align: "left",
                      "min-width": "100px"
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FasesStore).Fases, (fase) => {
                      return (_openBlock(), _createBlock(_component_el_table_column, {
                        key: fase.Id,
                        label: fase.Descripcion + '  (' + fase.Duracion + 'días)',
                        "min-width": "100px",
                        prop: "Fase",
                        align: "center"
                      }, {
                        header: _withCtx(() => [
                          _createTextVNode(_toDisplayString(fase.Descripcion) + " ", 1),
                          _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" (" + _toDisplayString(fase.Duracion) + " días) ", 1)
                        ]),
                        default: _withCtx((scope) => [
                          _createElementVNode("span", {
                            class: _normalizeClass(
                      'dot ' +
                      _unref(GetClassByStatus)(
                        (
                          scope.row.Fase &&
                          JSON.parse(scope.row.Fase).find(
                            (f) => f.Fase === fase.Id
                          )
                        )?.Estado || _unref(EstadosTareas).NotStarted
                      )
                    ),
                            style: {"text-align":"center","margin-right":"10px"},
                            "data-tooltip": 
                      (
                        scope.row.Fase &&
                        JSON.parse(scope.row.Fase).find(
                          (f) => f.Fase === fase.Id
                        )
                      )?.Estado || ''
                    
                          }, null, 10, _hoisted_2)
                        ]),
                        _: 2
                      }, 1032, ["label"]))
                    }), 128)),
                    _createVNode(_component_el_table_column, {
                      fixed: "right",
                      align: "right",
                      "min-width": "100px"
                    }, {
                      header: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          icon: _unref(Refresh),
                          circle: "",
                          class: "icon-idilia",
                          style: {"margin":"10px"},
                          onClick: RefreshData
                        }, null, 8, ["icon"])
                      ]),
                      default: _withCtx((scope) => [
                        _createVNode(_component_el_button, {
                          size: "small",
                          onClick: ($event: any) => (abrirProyecto(scope.row))
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" Detalle ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data", "default-sort"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})