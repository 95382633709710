import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "button-container" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "image__preview demo-image__error" }
const _hoisted_4 = { class: "image-slot" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "dotWithOutTooltip",
  style: 'background-color: #E00D0D !important;text-align: center;margin-right: 10px; color: transparent !important;'
}
const _hoisted_7 = { class: "image__preview demo-image__error" }
const _hoisted_8 = { class: "image-slot" }
const _hoisted_9 = {
  key: 0,
  class: "dotWithOutTooltip",
  style: 
                        'background-color: #E00D0D' +
                        ' !important;text-align: center;margin-right: 10px;' +
                        'color: transparent !important;'
                      
}
const _hoisted_10 = { style: {"display":"flex","justify-content":"flex-end"} }

import {
  ref,
  reactive,
  computed,
  defineAsyncComponent,
  onBeforeUnmount,
} from "vue";
import { Picture as IconPicture, Refresh } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { useProyectosStore, Proyecto } from "@/store/ProyectosStore";
import { registerMgtPersonComponent } from "@microsoft/mgt";
import {
  ProductoAcabado,
  useProductosAcabadoStore,
} from "@/store/ProductosAcabadoStore";
import { useCommonStore } from "@/store/CommonStore";
import { Material, useMaterialesStore } from "@/store/MaterialesStore";
import { EstadosTareas, useTareasStore } from "@/store/TareasStore";
import { onMounted } from "vue";
import Helper from "@/common/Helper";
import { ElMessageBox, ElNotification, UploadUserFile } from "element-plus";
import { FaseEstado, useFasesStore } from "@/store/FaseStore";

export default /*@__PURE__*/_defineComponent({
  __name: 'OnePageView',
  setup(__props) {

const FaseStore = useFasesStore();
const { DaysSince, dateToString, keyExistsEquipos } = Helper();
const GanttTasks = defineAsyncComponent(
  () => import("../components/GanttTasks.vue")
);
registerMgtPersonComponent();
const router = useRouter();
const route = useRoute();
const TareasStore = useTareasStore();
const ProyectosStore = useProyectosStore();
const ProductosAcabadoStore = useProductosAcabadoStore();
const MaterialesStore = useMaterialesStore();
const CommonStore = useCommonStore();
// const proyectoFoto = ref<UploadUserFile>({ name: '', url: ''})
const referenciaSelected = ref<ProductoAcabado>();
const Materiales = ref<Material[]>();
const panelVisible = ref(false);
const proyectoEmpty: Proyecto = {
  IdProyecto: 0,
  Descripcion: "",
  FechaServicio: "",
  Lideres: "",
  Foto: "",
  LoteMixto: false,
  LoteMixtoComponentes: "",
  GruposAcceso: "",
  EstimacionComercial: 0,
  Clientes: "",
  Fase: "",
  Creado: "",
  CreadoPor: "",
  Equipo: "",
  Completado: false,
  Duracion: 0,
  Iniciado: false,
  KeyEquipo: "",
  TareasProximasVencer: 0,
  TareasVencidas: 0
};
const loadingIniciarProy = ref(false);
let proyectoSeleccionado: Proyecto = reactive<Proyecto>(proyectoEmpty);
const goBack = () => {
  router.push({ name: "Dashboard" });
};
// const proySel = ProyectosStore.Proyectos?.find((data:Proyecto) => data.IdProyecto.toString() === route.params.Pid)
proyectoSeleccionado = ProyectosStore.ProyectoSelected || proyectoEmpty;
const productosAcabados = computed(() => {
  return ProductosAcabadoStore.ProductosAcabados;
});
const loading = computed(() => {
  return !TareasStore.TareasLoaded;
});
const getUrlPlanner = computed(() => {
  return (
    (process.env.VUE_APP_URLPLANNER as string) + proyectoSeleccionado.IdPlanner
  );
});
const openPanelMateriales = async (val: ProductoAcabado | undefined) => {
  if (val) {
    await MaterialesStore.GetMaterialesByProductoAcabado(val.IdPA);
  }
  referenciaSelected.value = val;
  panelVisible.value = true;
  Materiales.value = MaterialesStore.MaterialesPA;
};
const iniciarProyecto = async () => {
  loadingIniciarProy.value = true;
  if (proyectoSeleccionado.KeyEquipo && keyExistsEquipos(proyectoSeleccionado.KeyEquipo)) {
    if (productosAcabados.value && productosAcabados.value.length > 0) {
    for (let i = 0; i < productosAcabados.value.length; i++) {
      const pa = productosAcabados.value[i];
      await CommonStore.UpdatePlannerTasks(proyectoSeleccionado, pa, null);
      await MaterialesStore.GetMaterialesByProductoAcabado(pa.IdPA);
      if (
        MaterialesStore.MaterialesPA &&
        MaterialesStore.MaterialesPA.length > 0
      ) {
        for (let m = 0; m < MaterialesStore.MaterialesPA.length; m++) {
          const material = MaterialesStore.MaterialesPA[m];
          if (material.CambioDiseno) {
            await CommonStore.UpdatePlannerTasks(
              proyectoSeleccionado,
              pa,
              material
            );
          }
        }
      }
    }
  } else {
    await CommonStore.UpdatePlannerTasks(proyectoSeleccionado, null, null);
  }
  proyectoSeleccionado.Iniciado = true;
  const faseEstados: FaseEstado[] = [];
  if (FaseStore.Fases) {
    FaseStore.Fases.forEach((fase) => {
      faseEstados.push({ Fase: fase.Id, Estado: EstadosTareas.NotStarted });
    });
    proyectoSeleccionado.Fase = JSON.stringify(faseEstados);
  }
  const proyectoFoto: UploadUserFile = {
    name: "",
    url: proyectoSeleccionado.Foto,
  };
  ProyectosStore.UpdateProyecto(proyectoSeleccionado, proyectoFoto);
  ElNotification({
    title: "Proyecto iniciado",
    message:
      "El proyecto se ha iniciado correctamente. Es posible que necesite actualizar para ver todas las tareas correctamente.",
    type: "success",
    offset: 100,
  });
  } else {
    ElMessageBox.alert('No se ha encontrado ningún equipo para este proyecto. Es posible que el equipo ya no exista o no tenga tareas configuradas. Proyecto no iniciado.',
    'Atención', {
    confirmButtonText: 'OK',
  })
  }
  
  loadingIniciarProy.value = false;
};
const abrirProyecto = () => {
  MaterialesStore.EmptyMaterialesPA();
  router.push({ name: "Wizard", params: { Pid: route.params.Pid } });
};

const familia = computed(() => {
  return CommonStore.Familias?.filter(
    (item) => item.PROD_HIER === referenciaSelected.value?.Familia
  )[0];
});

const subfamilia = computed(() => {
  return CommonStore.Subfamilias?.filter(
    (item) => item.PROD_HIER === referenciaSelected.value?.Subfamilia
  )[0];
});

const formato = computed(() => {
  return CommonStore.Formatos?.filter(
    (item) => item.PROD_HIER === referenciaSelected.value?.FormatoComercial
  )[0];
});
const RefreshTareas = async () => {
  TareasStore.EmptyTareas();
  await ProductosAcabadoStore.GetProductoAcabadoById(Number(route.params.Pid));
  await TareasStore.GetByIdProyecto(Number(route.params.Pid));
};
onMounted(async () => {
  // await ProyectosStore.GetProyectoById(Number(route.params.Pid))
  await ProductosAcabadoStore.GetProductoAcabadoById(Number(route.params.Pid));
  await TareasStore.GetByIdProyecto(Number(route.params.Pid));
  if (!proyectoSeleccionado.Iniciado)
    await TareasStore.GetTareasConfiguracion()
});
onBeforeUnmount(async () => {
  TareasStore.EmptyTareas();
});

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_mgt_person = _resolveComponent("mgt-person")!
  const _component_el_statistic = _resolveComponent("el-statistic")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_drawer = _resolveComponent("el-drawer")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_page_header, { onBack: goBack }, {
      content: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "text-large font-600 mr-3" }, " OnePage ", -1)
      ])),
      extra: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (!_unref(proyectoSeleccionado).Iniciado)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                loading: loadingIniciarProy.value,
                class: "buttonIdilia",
                round: "",
                onClick: iniciarProyecto
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Iniciar proyecto ")
                ])),
                _: 1
              }, 8, ["loading"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_button, {
            class: "buttonIdilia",
            round: "",
            onClick: abrirProyecto
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Ver detalle ")
            ])),
            _: 1
          }),
          _createElementVNode("a", {
            href: getUrlPlanner.value,
            target: "_blank"
          }, [
            _createVNode(_component_el_button, {
              round: "",
              class: "buttonIdilia"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Ver tareas")
              ])),
              _: 1
            })
          ], 8, _hoisted_2)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, {
              gutter: 30,
              style: {"margin-bottom":"0px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_el_image, {
                        style: {"max-width":"200px","height":"100px","margin-left":"40px"},
                        src: _unref(proyectoSeleccionado).Foto,
                        "preview-src-list": [_unref(proyectoSeleccionado).Foto],
                        "initial-index": 0,
                        fit: "fill"
                      }, {
                        error: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IconPicture))
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["src", "preview-src-list"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 5 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, { gutter: 30 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(proyectoSeleccionado).Descripcion), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, null, {
                          default: _withCtx(() => [
                            _cache[5] || (_cache[5] = _createTextVNode(" Líderes: ")),
                            (
                    !_unref(proyectoSeleccionado).Lideres ||
                    _unref(proyectoSeleccionado).Lideres.trim().length === 0
                  )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " No se han seleccionado líderes. "))
                              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(proyectoSeleccionado).Lideres?.split(';'), (p) => {
                                  return (_openBlock(), _createBlock(_component_mgt_person, {
                                    key: p,
                                    "user-id": p,
                                    view: "oneline",
                                    "person-card": "hover",
                                    "fetch-image": ""
                                  }, null, 8, ["user-id"]))
                                }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 15 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 24 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_col, { span: 4 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_statistic, {
                                      title: "Tiempo transcurrido",
                                      value: _unref(DaysSince)(_unref(proyectoSeleccionado).Creado) + ' días'
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, { span: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_divider, {
                                      direction: "vertical",
                                      class: "divider-large"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, { span: 4 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_statistic, {
                                      title: "Tareas pendientes",
                                      value: _unref(TareasStore).GetNumberTareasPendientes.length
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, { span: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_divider, {
                                      direction: "vertical",
                                      class: "divider-large"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, { span: 4 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_statistic, {
                                      title: "Tareas fuera de plazo",
                                      value: _unref(TareasStore).GetNumberTareasDelayed.length
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, { span: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_divider, {
                                      direction: "vertical",
                                      class: "divider-large"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, { span: 4 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_statistic, {
                                      title: "Vencen los próximos 7 días",
                                      value: _unref(TareasStore).GetNumberTareasProximasVencer.length
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, { span: 1 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_divider, {
                                      direction: "vertical",
                                      class: "divider-large"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_col, { span: 4 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_statistic, {
                                      title: "Deadline",
                                      value: _unref(dateToString)(_unref(proyectoSeleccionado).FechaServicio)
                                    }, null, 8, ["value"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, { gutter: 30 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _cache[7] || (_cache[7] = _createTextVNode(" Referencias: ")),
                    _createVNode(_component_el_table, {
                      ref: "tableReferenciasPA",
                      data: productosAcabados.value,
                      style: {"width":"100%","max-height":"500px","box-sizing":"border-box"},
                      "empty-text": "No se han seleccionado referencias",
                      stripe: "",
                      "table-layout": "fixed",
                      onRowClick: openPanelMateriales
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_table_column, {
                          label: "Código",
                          width: "90",
                          property: "Codigo"
                        }),
                        _createVNode(_component_el_table_column, {
                          property: "Descripcion",
                          label: "Descripcion",
                          "show-overflow-tooltip": ""
                        }),
                        _createVNode(_component_el_table_column, {
                          width: "50",
                          "show-overflow-tooltip": ""
                        }, {
                          default: _withCtx((scope) => [
                            (scope.row.EditarCodigo)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Nueva referencia "))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["data"]),
                    _createVNode(_component_el_drawer, {
                      modelValue: panelVisible.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((panelVisible).value = $event)),
                      title: referenciaSelected.value?.Descripcion,
                      direction: "rtl",
                      size: "50%"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_el_image, {
                            style: {"max-width":"200px","height":"100px","margin-left":"40px"},
                            src: referenciaSelected.value?.Foto,
                            fit: "fill"
                          }, {
                            error: _withCtx(() => [
                              _createElementVNode("div", _hoisted_8, [
                                _createVNode(_component_el_icon, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(IconPicture))
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }, 8, ["src"])
                        ]),
                        _createVNode(_component_el_descriptions, {
                          title: "Datos de la referencia",
                          border: "",
                          column: 1
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_descriptions_item, { label: "Marca" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(referenciaSelected.value?.Marca), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_descriptions_item, { label: "Familia" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(familia.value?.PROD_HIER + " " + familia.value?.TEXT), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_descriptions_item, { label: "Subfamilia" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(subfamilia.value?.PROD_HIER + " " + subfamilia.value?.TEXT), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_descriptions_item, { label: "Formato" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(formato.value?.PROD_HIER + " " + formato.value?.TEXT), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "title" }, "Materiales", -1)),
                        _createVNode(_component_el_table, { data: Materiales.value }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_table_column, {
                              property: "Codigo",
                              label: "Código",
                              width: "150"
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "Descripcion",
                              label: "Descripcion"
                            }),
                            _createVNode(_component_el_table_column, {
                              width: "50",
                              "show-overflow-tooltip": ""
                            }, {
                              default: _withCtx((scope) => [
                                (scope.row.CambioDiseno)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Cambio diseño"))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "Tipo",
                              width: "100",
                              label: "Tipo"
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "Cantidad",
                              width: "100",
                              label: "Cantidad"
                            }),
                            _createVNode(_component_el_table_column, {
                              property: "UM",
                              width: "100",
                              label: "UM"
                            })
                          ]),
                          _: 1
                        }, 8, ["data"])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "title"])
                  ]),
                  _: 1
                }),
                _withDirectives((_openBlock(), _createBlock(_component_el_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_el_button, {
                        icon: _unref(Refresh),
                        circle: "",
                        class: "icon-idilia",
                        style: {"margin":"10px","float":"right"},
                        onClick: RefreshTareas
                      }, null, 8, ["icon"])
                    ]),
                    _createVNode(_unref(GanttTasks), { class: "left-container" })
                  ]),
                  _: 1
                })), [
                  [_directive_loading, loading.value]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})