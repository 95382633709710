import Axios from 'axios'
import './interceptors'
import AzureService from '../services/AzureService'
import FaseService from '@/services/FaseService'
import ProyectoService from '@/services/ProyectoService'
import ProductoAcabadoService from '@/services/ProductoAcabadoService'
import TareaService from '@/services/TareaService'
import StorageService from '@/services/StorageService'
import MaterialService from '@/services/MaterialService'
import PlannerService from '@/services/PlannerService'
import SAPService from '@/services/SAPService'
import ClienteService from '@/services/ClienteService'
import ConfiguracionService from '@/services/ConfiguracionService'

const apiUrl: string =
  process.env.VUE_APP_API_URL === undefined
    ? ""
    : process.env.VUE_APP_API_URL;


export default {
  AzureService: new AzureService(Axios),
  FaseService: new FaseService(Axios, apiUrl+"/data/"),
  ProyectoService: new ProyectoService(Axios, apiUrl+"/data/"),
  ProductoAcabadoService: new ProductoAcabadoService(Axios, apiUrl+"/data/"),
  TareaService: new TareaService(Axios, apiUrl+"/data/"),
  MaterialService: new MaterialService(Axios, apiUrl+"/data/",apiUrl+"/puntes/"),
  StorageService: new StorageService(Axios, apiUrl+"/storage/"),
  SAPService: new SAPService(Axios, apiUrl+"/sap/"),
  PlannerService: new PlannerService(Axios, apiUrl+"/planner/"),
  ClienteService: new ClienteService(Axios, apiUrl+"/data/"),
  ConfiguracionService: new ConfiguracionService(Axios, apiUrl+"/data/"),
};
