import { Cliente } from "@/store/ClientesStore";
import { AxiosResponse, AxiosStatic } from "axios";

class ClienteService {
  axios;
  baseUrl;

  constructor(axios: AxiosStatic, apiUrl: string) {
    this.axios = axios;
    this.baseUrl = `${apiUrl}AllCliente`;
  }

  getAll(): Promise<AxiosResponse<Cliente[]>> {
    return this.axios.get(`${this.baseUrl}`);
  }
}

export default ClienteService;
