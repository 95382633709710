import { Tarea } from "@/store/TareasStore";
import { AxiosResponse, AxiosStatic } from "axios";

class TareaService {
  axios;
  baseUrl;
  baseUrlAux;


  constructor(axios: AxiosStatic, apiUrl: string) {
    this.axios = axios;
    this.baseUrl = `${apiUrl}Tarea`;
    this.baseUrlAux = `${apiUrl}`;
  }

  getAll() {
    return this.axios.get(`${this.baseUrl}`);
  }

  getByIdProyecto (id: number): Promise<AxiosResponse<Tarea[]>> {
    return this.axios.get(`${this.baseUrlAux}/TareaByIdProyecto?id=${id}`)
  }

  update(data: Tarea): Promise<AxiosResponse<Tarea>> {
    return this.axios.put(`${this.baseUrl}`, data);
  }

  add(data: Tarea): Promise<AxiosResponse<Tarea>> {
    return this.axios.post(`${this.baseUrl}`, data);
  }

  delete (id: number) {
    return this.axios.delete(`${this.baseUrl}/?id=${id}&deleteRefs=0`) // De momento las referecias no se eliminan
  }
}

export default TareaService;
