import { ProductoAcabado } from "@/store/ProductosAcabadoStore";
import { AxiosResponse, AxiosStatic } from "axios";

class ProductoAcabadoService {
  axios;
  baseUrl;
  baseUrlAux;
  baseUrlAll;
  constructor(axios: AxiosStatic, apiUrl: string) {
    this.axios = axios;
    this.baseUrl = `${apiUrl}ProductoAcabado`;
    this.baseUrlAux = `${apiUrl}ProductoAcabadoByIdProyecto`;
this.baseUrlAll =  `${apiUrl}AllProductoAcabadoEnCurso`;
  }

  getAll() {
    return this.axios.get(`${this.baseUrl}`);
  }

  getByIdProyecto (id: number): Promise<AxiosResponse<ProductoAcabado[]>> {
    return this.axios.get(`${this.baseUrlAux}/?id=${id}`)
  }

  update (data: ProductoAcabado): Promise<AxiosResponse<ProductoAcabado>> {
    return this.axios.put(`${this.baseUrl}/?id=${data.IdPA}`, JSON.stringify(data))
  }

  add(data: ProductoAcabado): Promise<AxiosResponse<ProductoAcabado>> {
    return this.axios.post(`${this.baseUrl}`, JSON.stringify(data));
  }

  delete (id: number) {
    return this.axios.delete(`${this.baseUrl}/?id=${id}&deleteRefs=0`) // De momento las referecias no se eliminan
  }
  getAllProductoAcabadoEnCurso (): Promise<AxiosResponse<ProductoAcabado[]>> {
    return this.axios.get(`${this.baseUrlAll}`);
  }
}

export default ProductoAcabadoService;
