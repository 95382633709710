import { Configuracion } from "@/store/CommonStore";
import { AxiosResponse, AxiosStatic } from "axios";

class ConfiguracionService {
  axios;
  baseUrl;
  baseUrlAux;
  constructor(axios: AxiosStatic, apiUrl: string) {
    this.axios = axios;
    this.baseUrl = `${apiUrl}Configuracion`;
    this.baseUrlAux = `${apiUrl}AllConfiguracion`;
  }

  getAll(): Promise<AxiosResponse<Configuracion[]>> {
    return this.axios.get(`${this.baseUrlAux}`);
  }

  getByKey (key: string): Promise<AxiosResponse<Configuracion>> {
    return this.axios.get(`${this.baseUrl}?id=${key}`)
  }

  update (data: Configuracion): Promise<AxiosResponse<Configuracion>> {
    return this.axios.put(`${this.baseUrl}?id=${data.Key}`, JSON.stringify(data))
  }

  add(data: Configuracion): Promise<AxiosResponse<Configuracion>> {
    return this.axios.post(`${this.baseUrl}`, JSON.stringify(data));
  }

  delete (key: string) {
    return this.axios.delete(`${this.baseUrl}?id=${key}`) // De momento las referecias no se eliminan
  }
}

export default ConfiguracionService;
